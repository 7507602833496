import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

import { PreregisterComponent } from './views/register/pre-register.component';
import { VotingComponent } from './vote/voting/voting.component';

import { SessionEndedComponent} from './views/error/session-ended.component';
import { AsambleaEndedComponent} from './views/error/asamblea-ended.component';
import { ExternalRegisterComponent } from './views/register/external-register.component';
import { ConsultaraccesoComponent } from './views/register/consultarAcceso.component';
import { RegisterEntryComponent } from './views/register/register-entry.component';
//import { FormVerificarPoderComponent } from './views/base/formVerificarPoder.component';
//import { LoginUserComponent } from './views/login/loginUser.component';

/*
export const routes: Routes = [
  {
    path: '',
    //redirectTo: 'dashboard',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  { path: '**', component: P404Component }
];
*/
export class AccessAuth
{
    perfil:string;

    AccessAuth()
    {
      console.log("AccessAuth::::");

      this.perfil = localStorage.getItem("Perfil");
    }

    get _404Page()
    {
      return '404';
    }
    public _GetSalirPage()
    {
      return 'Salir';
    }


}

export const routes: Routes = [
  {
    path: '',
    //redirectTo: 'dashboard',
    //redirectTo: 'login',
    redirectTo:'base/votacionenlinea',
    pathMatch: 'full'
  },
  {
    //path: '404',
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'Salir',
    //path: (new AccessAuth)._GetSalirPage(),
    component: SessionEndedComponent,
    data: {
      title: 'Cerrar sesión'
    }
  },
  {
    path: 'Finalizar',
    //path: (new AccessAuth)._GetSalirPage(),
    component: AsambleaEndedComponent,
    data: {
      title: 'Cerrar sesión'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    //redirectTo: 'dashboard',
    data: {
      title: 'Login Page'
      //title: 'Home'
    }
  },
  {
    path: 'login/:AsambleaId/:ParticipanteId',
    component: LoginComponent,
    //redirectTo: 'dashboard/:AsambleaId/:ParticipanteId',
    data: {
      title: 'Login Page'
      //title: 'Home'
    }
  },/*
  {
    path: '',
    component: VotosComponent,
    data: {
      title: 'Register Page'
    }
  },*/
  {
    path: 'register/SPB',
    component: RegisterComponent,
    data: {
      title: 'Pagina de Regidtro SPB'
    }
  },
  {
    //path: 'Registro/Pre-Registro-SPB',
    path: 'Registro/:AsambleaId/:token',
    component: PreregisterComponent,
    data: {
      title: 'Pagina de Pre-registro'
    }
  },
  {
    //path: 'Registro/Pre-Registro-SPB',
    path: 'Registro/mi-asamblea',
    component: ConsultaraccesoComponent,
    data: {
      title: 'Mi Asamblea'
    }
  },
  /*{
    path: 'SPRBUN/verificar-poderes',
    component: FormVerificarPoderComponent,
    data: {
      title: 'Verificación de Pre-registro'
    }
  },*/
  {
    //path: 'pre-register',
    path: 'Registro/Portal-183/External-register',
    component: ExternalRegisterComponent,
    data: {
      title: 'Pagina de registro en linea'
    }
  },
  {
    path: 'register/register-entry',
    component: RegisterEntryComponent,
    data: {
      title: 'Registro de asamblea mixta'
    }
  },
  {
    //path: 'pre-register',
    path: 'Registro/Cantabria4/External-register',
    component: ExternalRegisterComponent,
    data: {
      title: 'Pagina de registro en linea'
    }
  },

  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'dashboard/:AsambleaId/:ParticipanteId',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'votaciones',
        loadChildren: () => import('./views/votaciones/votaciones.module').then(m => m.VotacionesModule)
      },
      {
        //path: 'widgets',
        path: 'voting',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
