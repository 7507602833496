import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import {ILogin, IMessage } from "../Models/login";
//import { IMessage, IMessage } from '../Models/login';


@Injectable()
export class LoginService{

    //public ListaMensajes: IMessage[];
    public ListaMensajes: IMessage[];

    public Url="https://localhost:44344/SIP/Autentica/Start/1";
    constructor(private http: HttpClient)
    { 
        console.log("Login Service reponding;");

        http.get<IMessage[]>(this.Url).subscribe(  
             result => { 
                        console.log(result);
                     }, error => console.error(error)
                     
                     //ListaMensajes = result;                 
                     
                     );

                    // this.ListaMensajes = result;        
        
    };

    
    private QuorumData: ILogin = {
        Nombre:"Harold Villa A",
        Usuario: "usuario",
        Password: "123456"
    }

    
}


@Injectable({
    providedIn: "root"
  })
export class LoginService2
{
   private login : ILogin = {Nombre:"",Usuario:"", Password:""}

   private ListaMensajes: IMessage[];

   private Url="https://localhost:44344/SIP/Autentica/Start/1";
   
  constructor(private http: HttpClient) {}

  public getMessage(): Observable<IMessage[]> {

    return this.http.get<IMessage[]>(this.Url);
    
  }

}



/*
export interface IMessage
{
        mensaje: string;
        tipo: string;

}
*/
/*
export interface ILogin
{
        Nombre: string;
        Usuario: string;
        Password : string;

}*/