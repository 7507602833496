// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase :{
    apiKey: "AIzaSyCF3s5OnoPNim_8lTUbaZOfYvNL0Xogaq8",
    authDomain: "chatasamblea.firebaseapp.com",
    databaseURL: "https://chatasamblea.firebaseio.com",
    projectId: "chatasamblea",
    storageBucket: "chatasamblea.appspot.com",
    messagingSenderId: "207570631538",
    appId: "1:207570631538:web:9264167233c9f782d3bbeb",
    measurementId: "G-1R7KZ5SVFS"
  },
  AsambleaVirtualDB:{
    apiKey: "AIzaSyBrcmXvbTIbjBcgfoVO0hsEr6VdSnb_L0Y",
    authDomain: "asambleavirtualdb.firebaseapp.com",
    databaseURL: "https://asambleavirtualdb.firebaseio.com",
    projectId: "asambleavirtualdb",
    storageBucket: "asambleavirtualdb.appspot.com",
    messagingSenderId: "341527512731",
    appId: "1:341527512731:web:39502c01f2b4e4b7cde83e",
    measurementId: "G-G9VMKMHH6Z"
  },

  /* AsambleaonlineInfo  the really project */
  AsambleaonlineInfo : {
    apiKey: "AIzaSyA-IoLtrju2fMiHlA_YNPYYVbk4P5FptnU",
    authDomain: "asambleaonlineinfo.firebaseapp.com",
    databaseURL: "https://asambleaonlineinfo.firebaseio.com",
    projectId: "asambleaonlineinfo",
    storageBucket: "asambleaonlineinfo.appspot.com",
    messagingSenderId: "801989695715",
    appId: "1:801989695715:web:66d8e791f1701d4e588dd6",
    measurementId: "G-PHCPGP9J2Q"
  },

  InfosolConfig:
  {
       IFCLogoUrl:""
      ,AsambleaVirtualUrl:"https://www.infosolcolombia.com/asamblea-virtual"
      ,AsambleaStorageKey:"Asamblea"
      ,KriptoKey:"Lamismaclave1*"
      ,sendMailMethod:"https://api.sendinblue.com/v3/smtp/email"
    , sendAPIKEY: "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"
      ,meetServer:"meet.asambleavirtual.com.co"
  },
  CollectionPath:
  {
    Usuario:"/Usuario"
   ,UsuarioLista:"/Usuario/Lista"
   ,Votaciones:"/Votaciones"
   ,VotacionesLista:"/Votaciones/Votos"
   ,Voto:"/Voto/Lista"
   ,VotoLista:"/Voto/Lista"
   ,Opciones:"/Opciones"
   ,OpcionesLista:"/Opciones/Lista"
   ,Voting:"/Voting"
   ,VotingLista:"/Voting/Lista"
   ,Quorum:"/Quorum"
   ,QuorumLista:"/Quorum/Lista"
   ,Inmueble:"/Inmueble"
   ,InmuebleLista:"/Inmueble/Lista"
 }

};

/*

  AsambleaVirtualDB:{
    apiKey: "AIzaSyBrcmXvbTIbjBcgfoVO0hsEr6VdSnb_L0Y",
    authDomain: "asambleavirtualdb.firebaseapp.com",
    databaseURL: "https://asambleavirtualdb.firebaseio.com",
    projectId: "asambleavirtualdb",
    storageBucket: "asambleavirtualdb.appspot.com",
    messagingSenderId: "341527512731",
    appId: "1:341527512731:web:39502c01f2b4e4b7cde83e",
    measurementId: "G-G9VMKMHH6Z"
  }
*/
