import { Component, OnInit, OnDestroy} from '@angular/core';
//import { LoginService } from './../../services/login.service'
import { LoginService, LoginService2} from './../../services/login.services'
import { IMessage, ILogin } from '../../Models/login';

import { Router,ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data/data.service';
import { IInmueble, IRegistroQuorum, IAsambleaActiva, IQuorumActual } from '../../Models/Vote/Votacion';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';

import { HttpClient } from '@angular/common/http';
//import {StorageService} from './../../services/localStorage/StorageService';

import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-dashboard',
  //selector: 'app-login',
  templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit, OnDestroy 
{

  public mensaje : IMessage[];
  AsambleaId: String;
  ParticipanteId: String;
  AsambleaActiva:IAsambleaActiva;
  url:string;
  AUTH0:Boolean;
  inmueble:IInmueble;

  loginStateMssage:string;

  deviceInfo = null;

  constructor( private _loginService:LoginService2,
    private _dataService      :DataService,
                 private router :Router,
                 private route :ActivatedRoute,
                 private httpClient: HttpClient,
                 private deviceService: DeviceDetectorService
    )
  {
    console.log("LognService works.!");

    localStorage.clear();
    sessionStorage.clear();

    this.WhatDevice();

    let hora = new Date();

    let mili = hora.getTime() - 3600000;

    hora.setTime(mili);
    console.log("::::::::::::::",hora.toTimeString());
    hora.toLocaleTimeString();

     this.AsambleaId      = this.route.snapshot.paramMap.get("AsambleaId");
     this.ParticipanteId  = this.route.snapshot.paramMap.get("ParticipanteId");
     this.AsambleaMapper();

     console.log(" this.ParticipanteId   login::::::::::::::",this.ParticipanteId );

     this.GetToken();

     //this.DekryptParticipante();

     //console.log(" ggggggggggglogin::::::::::::::",this.ParticipanteId );
     
  }
  ngOnDestroy(): void {
    //throw new Error("Method not implemented.");
    let data = "Saliendo del login " + new Date().toString();
    localStorage.setItem("LoginOut",data);
    //this.quorumCalcSubs.unsubscribe();

  }

  loginStatus:string = "Un momento por favor, estamos validando sus credenciales...";
  async GetToken()
  {
    let result = "";

    let path = this.AsambleaId.toString().trim()+"/Inmueble/Lista/"
    var inmuebleRef = this._dataService.GetData(path);


    // Create a query against the collection.
   // var qryInmueble = inmuebleRef.where("token", "==", this.ParticipanteId.trim());
   let token = this.ParticipanteId.trim().toString();
   this.ParticipanteId = "";
   console.log("token::token:::token", token);
   //if(token=""){return;}

   var qryInmueble = inmuebleRef.where("token", "==", token).where("estado", "==", "1");

   console.log("ParticipanteId :::::",this.ParticipanteId.trim().toString());
   console.log("token ParticipanteId :::::",token);

   // this.ParticipanteId="0000";
    
    await qryInmueble.get()
    .then((res)=>{

          console.log("TOKEN TOKEN suc :::: ",res.docs.length);
          if(res.docs.length===0)
          {
            this.loginStatus = "Credenciales Rechazadas, acceso negado.";
            this.router.navigateByUrl('404');
          }else{

            res.forEach(element => {

              this.inmueble =  ((element.data()) as IInmueble);
              this.ParticipanteId = ((element.data()) as IInmueble).codigo.toString();
              this.loginStatus = "Credenciales validadas..!!,  bienvenido";

              //this.LockToken(this.inmueble.codigo);

            });
          }
    })
    .catch((error)=>{
        
        console.log("TOKEN TOKEN fail :::: ",error);
        this.loginStatus = "Credenciales no validadas";

    })
    
   // return result;
  }

  async LockToken(_inmuebleCodigo:string)
  {
    let path = this.AsambleaId.toString().trim()+"/Inmueble/Lista/";
    var inmuebleRef = this._dataService.GetData(path);
    
   await  inmuebleRef.doc(_inmuebleCodigo).update({
       token:"", estado:"2"
    }).then((resp)=>{console.log("Ingreso OK",resp);})
    .catch((error)=>{
  
        console.log("TOKEN TOKEN fail :::: ",error);
        this.loginStatus = "Credenciales";

    })
  }
  


  WhatDevice() {
    ///console.log('hello `Home` component');

    this.deviceInfo          = this.deviceService.getDeviceInfo();    
    const isMobile           = this.deviceService.isMobile();
    const isTablet           = this.deviceService.isTablet();
    const isDesktopDevice    = this.deviceService.isDesktop();

    let name = isMobile===true ? "Mobile" : "Tablet";

    name = isDesktopDevice ===true ? "Desktop" : name;

    localStorage.setItem("DeviceInfo", JSON.stringify({ deviceName : name, isMobile:isMobile }));

  }

  EnkryptParticipante()
  {
     
     if(this.ParticipanteId!=undefined){
    
      this.ParticipanteId  = this.ParticipanteId;
     }

     return this.ParticipanteId;
  }

  DekryptParticipante()
  { 
   
     if(this.ParticipanteId!=undefined){

     this.ParticipanteId  = this.ParticipanteId  ;  

     console.log("this.ParticipanteId::::",this.ParticipanteId);

     }
  }


  Acceder(){

    console.log(" acceder... ");

    var use = document.getElementById("user");
    var pas = document.getElementById("passw");

    console.log(use.innerText);
    console.log(pas.innerText);

    //this.router.navigate[('/dashboard')];
    console.log(" acceder... ");
  }

  async ngOnInit()
  {
  
    await this.AsambleaMapper();

    await this.IsAsambleaActiva();

    await this.AuthParticipante();

  }

  videoRoomName:string;

  async GetAsambleaConfig()
  {
      
    //let path  = this.AsambleaId + "/AsambleaActiva";

    let refDoc = this._dataService.GetData(this.AsambleaId.toString()).doc("AsambleaActiva");

    await refDoc.get()
    .then((doc)=>{
          console.log("El documento asamblea XXXXXXXXX", doc);
    })
    .catch((error)=>{});
    
    this.videoRoomName = sessionStorage.getItem("videoRoomName");

  }

  async AsambleaMapper()
  {
      if(this.AsambleaId==="simulacro"){this.AsambleaId="Simulacro"; }
      if(this.AsambleaId==="Torresmayor"){this.AsambleaId="Torresmayor2"; }
  }

  // Se verifica que la asamblea este activa para el momento
   async IsAsambleaActiva(){  /// pendiente utilizar para validar la asamblea activa
     
    let strfecha = Date().toString();  

    this.AsambleaActiva = {
      $Key            :"",
      activa          :false,  //AsambleaActiva o no activa
      nombreAsamblea : "",
      fechaInicio     :strfecha,
      fechaFin        :strfecha,
      videoRoomName   :"",
      ytbLiveStream   :""    //Stream a youtube desde  JITSI
    }    as IAsambleaActiva;

    //let path = this.AsambleaId + "/" ;
    // console.log("this.AsambleaId.toString()::::::", this.AsambleaId.toString());
    let refDoc = this._dataService.GetData(this.AsambleaId.toString()).doc("AsambleaActiva").get();

    await refDoc
    .then((resp)=>{

       this.AsambleaActiva = resp.data() as IAsambleaActiva;

       //console.log(" RESP REPS:::::",resp.data);
       console.log(" RESP REPS  this.AsambleaActiva:::::",this.AsambleaActiva);
       
       localStorage.setItem("nombreAsamblea",this.AsambleaActiva.nombreAsamblea);


    })
    .catch((error)=>{console.log(error)});


  }

  async AuthParticipante()
  {
    //try{

     //this.AsambleaActiva.activa=true;

      //let codigo =  this.krypto.Decript(this.ParticipanteId.toString());
    if(this.AsambleaActiva.activa===true && this.AsambleaActiva.estado!="f" )
    {
      this.AUTH0 = false;

      //let inmueble: IInmueble;

      let codigo = this.ParticipanteId;
      let inmuebleLista = environment.CollectionPath.InmuebleLista;
      
      let pathParticipante = this.AsambleaId.concat(inmuebleLista); 
      

      // 24 jul 2020
      let partRef =  this._dataService.GetData(pathParticipante).doc(codigo.toString()).get();

      localStorage.removeItem("Inmueble");
      localStorage.removeItem("Asamblea");



      /*  ojo cambio 24 jul 2020 */
      await partRef.then((res)=>{
            this.inmueble = res.data() as IInmueble;
            console.log(" Inmueble login :",this.inmueble);
            //this.AUTH0 = true;
      }).catch((error)=>
      {
        console.log(error);
        this.AUTH0 = false;
      });



      

      if(this.inmueble!=undefined)
      { 

            if(this.inmueble.codigo.toString() === codigo.toString())
            {
              
              this.AUTH0 = true;

               console.log(" Inmueble login 2   this.AUTH0 :",this.AUTH0);

              //localStorage
              localStorage.setItem("Inmueble",JSON.stringify(this.inmueble));
              localStorage.setItem("Asamblea",this.AsambleaId.toString());
              localStorage.setItem("ParticipanteId",this.ParticipanteId.toString());
              localStorage.setItem("Perfil",this.inmueble.activaCamara.toString());

              //localStorage.setItem("Asamblea",this.AsambleaId.toString());
          
              // SessionStorage
              sessionStorage.setItem("Asamblea",this.AsambleaId.toString());
              sessionStorage.setItem("ParticipanteId",this.ParticipanteId.toString());
              sessionStorage.setItem("AsambleaActiva",JSON.stringify(this.AsambleaActiva));
            }
      }

      //onsole.log("AuthParticipante document: ", this.inmueble);
      //console.log("AUTH ***: ", this.AUTH0);

      if(this.AUTH0 === false)
      { 
        this.url = '404';
      }else
      {
         this.RegistrarEnQuorum();  
         this.url = '/dashboard/'+this.AsambleaId+"/"+  this.EnkryptParticipante();

         if(this.AsambleaId==="Neosvittra"){
          //this.url = '/base/'+this.AsambleaId+"/"+  this.EnkryptParticipante();
          //this.url = '/base/votacionenlinea';
         }else{
          
         }

        /* if(this.AsambleaId==="Encuestaportal"){

          this.url = '/base/'+this.AsambleaId+"/"+  this.EnkryptParticipante();
         }else{
          
         }*/

         
      }

      console.log("auth0: ", this.url);

      this.router.navigateByUrl(this.url);
    }else
    {
            this.router.navigateByUrl('500');
    }

  }

  quorumCalcSubs: Subscription;

  ListaRegistrados: IRegistroQuorum[] =[];

   SumaCoeficiente:number=0;
   Asistentes:number=0;

  /// Calcula el quorum tomando toda la lista de los registrados
  async CalcularQuorum(){

    let path = this.AsambleaId+"/QuorumActual/RegistroQuorum/";

    let refDoc = this._dataService.GetDataObs(path);

    this.quorumCalcSubs = await refDoc.subscribe((resp)=>{
      this.ListaRegistrados = [];
      this.SumaCoeficiente=0;
      this.Asistentes=0;

      resp.forEach((item)=>{ 
            
            this.ListaRegistrados .push(item.data() as IRegistroQuorum);

            this.SumaCoeficiente += parseFloat((( item.data() as IRegistroQuorum).coeficiente).toString());

            this.Asistentes +=1;

            console.log("item.data() : ", item.data() );
            

          });

          let quorumActual =  { acumulado: this.SumaCoeficiente, asistentes:this.Asistentes, fecha: (new Date()).toString() } as IQuorumActual;
          
          localStorage.setItem("QuorumActual", JSON.stringify(quorumActual));

          console.log("SumaCoeficiente: ",this.SumaCoeficiente);

          console.log("Asistentes: ",this.Asistentes);

    });
}
  

  /// Registra en el quorum el coeficiente
   async RegistrarEnQuorum()
  {
        /**
         * Debe crearse un cloud functions que  calcule el total de gente registrada y que sume 
         * a la variable global.
         * La funcion debe llamarse desde: 
         * 1: La interfaz de usuario Admin
         * 2: Desde otra function que este programada y automaticamente la llame
         *
        */

        if(this.inmueble.coeficiente>0)
        {

          let fecha =  new Date().toString();
        
          /* Modificacion por invitados */
          //let numAsist = this.inmueble.asistentes <=0 ? 0 : this.inmueble.asistentes;
          let numAsist = this.inmueble.coeficiente <=0 ? 0 : this.inmueble.totalCoeficiente;

          let Quorum = 
          {
                $Key : this.inmueble.codigo,
                inmueble : this.inmueble.codigo,
                coeficiente : this.inmueble.coeficiente,
                asistentes: numAsist,
                nombre:this.inmueble.propietario,
                puedeVotar:this.inmueble.puedeVotar,
                fecha:   (new Date()).toString()
          } as IRegistroQuorum
  
          console.log("El Quorum a registrar::: ",Quorum);
  
          let path = this.AsambleaId+"/QuorumActual/RegistroQuorum/";
          
          let refDoc = this._dataService.Create(path).doc(this.inmueble.codigo.toString()).set(Object.assign({},Quorum)); 
  
          await refDoc.then( (resp)=>
              {
                
                let   QActual:IQuorumActual = 
                {
                     acumulado:Quorum.coeficiente, asistentes:numAsist ,fecha: Quorum.fecha 
                } as IQuorumActual;
                
                localStorage.setItem("QuorumActual", JSON.stringify(QActual));
                
                console.log("Quorum registrado..",QActual);
  
              }
          ).catch((error)=>
          {
             console.log(error)
            }
          );
        }
      }

  verificar(){

     return this.mensaje[0].mensaje;
  }
}
