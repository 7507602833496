import { Component } from '@angular/core';
//import {} from './../../';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'asamblea-ended.component.html'
})
export class AsambleaEndedComponent {

  fecha:Date;
  AsambleaId:String;
  NombreAsamblea:string;

  constructor() { 

    this.fecha= new Date();
    this.NombreAsamblea = "Infosol Colombia | Asamblea";
    this.AsambleaId     =  localStorage.getItem("Asamblea");
    this.NombreAsamblea =  localStorage.getItem("nombreAsamblea");
    sessionStorage.clear();
    localStorage.clear();
  }

}
