import { NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
//import { LoginUserComponent } from './views/login/loginUser.component';

import { RegisterComponent } from './views/register/register.component';


//import * as jitsi from 'angular-jitsi-meet';

// Import customs
//import { TabsComponent } from './views/base/tabs.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';



// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule, TabsetComponent } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';


// Modulos
//import { BaseModule } from './views/base/base.module';
import {AngularFireModule} from '@angular/fire'; 
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import {environment} from  './../environments/environment';


// componentes

//import{ChatComponent} from './dashboard/chat/chat.component';

//import { VotacionesModule } from './views/votaciones/votaciones.module';
//import { VotacionesRoutingModule } from './views/votaciones/votaciones-routing.module';

// Import services
import { DashboardService } from './services/dashboard.service'; 
import { LoginService, LoginService2 } from './services/login.services';
import { ChatService } from './services/chat/chat.service';
import { DataService } from './services/data/data.service';
import { CommonToolService } from './services/common/common-tool.service';

import {CustomerService} from './services/customer/customerservices';

import { HttpClientModule } from '@angular/common/http';
import { AccionariaComponent } from './dashboard/custom/accionaria/accionaria.component';

import { VotingComponent } from './vote/voting/voting.component';
//import { VotosComponent } from './views/votaciones/votos/votos.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PreregisterComponent } from './views/register/pre-register.component';
import { ExternalRegisterComponent } from './views/register/external-register.component';
import {SessionEndedComponent} from './views/error/session-ended.component';
import {AsambleaEndedComponent} from './views/error/asamblea-ended.component';
//import {FormVerificarPoderComponent} from './views/base/formVerificarPoder.component';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { FileLoaderService } from './services/file-loader.service';
import { FileLoaderComponent } from './dashboard/custom/fileLoader/file-loader/file-loader.component';
import { NgDropFilesDirective } from './directives/ng-drop-files.directive';
//import {MatRadioModule} from '@angular/material/radio';
import {TableModule} from 'primeng/table';

import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import { DataTablesModule } from 'angular-datatables';
import { PreguntassecComponent } from './dashboard/custom/preguntas/preguntas-sec.component';
import {SendinblueService} from './services/sendinblue/sendinblue.service';
///import {StorageService} from './services/localStorage/StorageService';
//import { VotoActualComponent } from './dashboard/custom/voto-actual/voto-actual.component';
import {ConsultaraccesoComponent} from './views/register/consultarAcceso.component';
import { RegisterEntryComponent } from './views/register/register-entry.component';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    ChartsModule,
    HttpClientModule
    //,AngularFireModule.initializeApp(environment.firebase)
    ,AngularFireModule.initializeApp(environment.AsambleaVirtualDB)
    ,AngularFireStorageModule
    ,AngularFireDatabaseModule
    ,FormsModule
    ,ReactiveFormsModule
    ,DataTablesModule
    ,TableModule
    //,TableModule
    //,MatRadioModule
    //,VotacionesRoutingModule
    //,VotacionesModule
   // ,AngularFirestore
   // ,ChatComponent
    ,ToastModule
    ,CalendarModule
    ,SliderModule
    ,MultiSelectModule
    ,ContextMenuModule
    ,DialogModule
    ,ButtonModule
    ,DropdownModule
    ,ProgressBarModule
    ,InputTextModule
   
  ],
  declarations: [
    AppComponent,
    APP_CONTAINERS,
    P404Component
    ,P500Component
    ,LoginComponent
    ,RegisterComponent
    ,AccionariaComponent
    ,VotingComponent
    ,PreregisterComponent
    //,FormVerificarPoderComponent 
    ,SessionEndedComponent
    ,AsambleaEndedComponent
    ,ExternalRegisterComponent
    ,FileLoaderComponent
    ,NgDropFilesDirective
    ,PreguntassecComponent
    ,ConsultaraccesoComponent
    ,RegisterEntryComponent
    //,FormVerificarPoderComponent
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }, 
      DashboardService, LoginService, LoginService2, ChatService, 
      DataService, CommonToolService, FileLoaderService, CustomerService, SendinblueService
    ],
  bootstrap: [ AppComponent ],
  schemas: [
      CUSTOM_ELEMENTS_SCHEMA,
      NO_ERRORS_SCHEMA
    ]
})
export class AppModule { }
