import { Directive, EventEmitter, ElementRef,
        HostListener, Input, Output } from '@angular/core';

import { FileItem } from './../../app/services/common/filre-item';

@Directive({
  selector: '[appNgDropFiles]'
})
export class NgDropFilesDirective {

 
  constructor() { }

  @Input() documentos: FileItem[]=[];
  @Output() mouseOver: EventEmitter<boolean> = new EventEmitter();

  @HostListener('dragover', ['$event'])
  public onDragEnter(event:any){
      
    this.mouseOver.emit(true);

    this._prevenOpenDoc( event );

  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event:any){
      this.mouseOver.emit(false);
  }

  @HostListener('drop', ['$event'])
  public onDrop(event:any){
    
    const transferencia  = this._getTransferencia( event );

    if( !transferencia ){ return;}

    this._extraerArchivos( transferencia.files );

    this._prevenOpenDoc( event );

    this.mouseOver.emit(false);

  }

  private _extraerArchivos(documentosLista :FileList ){
        
        console.log(documentosLista);

        for(const propiedad in Object.getOwnPropertyNames(documentosLista)){
          
            const docTemp = documentosLista[propiedad];

            let fileItem = new FileItem(docTemp);

            if(this._canToAddDoc(fileItem)===true)
            {
              this.documentos.push(fileItem); 
            }

        }

        console.log(this.documentos);  
  }

  private _getTransferencia(event : any)
  {
     return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
  }


  // validaciones
  private _prevenOpenDoc( event )
  {
        event.preventDefault();

        event.stopPropagation();
  }


  private _canToAddDoc(file:FileItem):boolean{

    this._extIsOk(file);

    if(  this._fileSoBig(file) === false &&  this._docAlreadyInzone(file.nombreArchivo)===false)
    {
        return true;
    }
    else
    {
        return false; 
    }
  }

  /// determina si la extension es aceptada
  private _extIsOk(file:FileItem):boolean{

      let result:boolean= false;

      let extension =  file.archivo.type.toUpperCase();

      console.log(extension);

      if(extension ==="JPG" || extension ==="PNG" || extension ==="PDF"){ result = true; }

      return result;
  }

  private _docAlreadyInzone(fileName:string): boolean
  {
    for(const doc of this.documentos){
        if(doc.nombreArchivo===fileName){
          console.log("Este archivo ya esta cargado.");
          return true;          
        }
    }
      return false;

  }

  
  /// verificado a 200k solamente, 
  private _fileSoBig(file: FileItem):boolean
  {
     
    //let isSobig:boolean = file.archivo.size > 5000000 ? true : false;
    let isSobig:boolean = file.archivo.size > 300000 ? true : false;
    
    return isSobig;

  }

}
