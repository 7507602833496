import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class FileLoaderService {

  AsambleaId:string;

  private FILES_FOLDER:string;

  constructor() { }



}
