import { Injectable, ɵConsole, EventEmitter } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import { AngularFireDatabase,AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
//import { EventEmitter } from 'protractor';
import * as firebase from 'firebase';

// interfaces
import {Voto, Opcion, IVoto, IOpcion,IChatMessage,NM_ESTADO_VOTO} from './../../Models/Vote/Votacion';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  //Votos : AngularFireList<any[]>;
  //Votos :Observable<any[]>;
  //Votos : AngularFirestoreCollection;
Votos : AngularFirestoreCollection<any[]>;

nombresss$ = new EventEmitter<string>();

constructor(private db : AngularFirestore) 
{ 
    console.log("Data service works....");
}


public GetTimeStamp()
{
 //this.db.firestore.time
 let value  = firebase.firestore.FieldValue.serverTimestamp();
 console.log("GetTimeStamp:::::",value);
}

//CrearVoto(coleccion: string, dataObject:IVoto)
public Create(path: string)
{
  return this.db.firestore.collection(path);
  
}

//Eliminar(coleccion: string, dataObject:IVoto)
public Delete(path: string, id:string)
{

  return this.db.collection(path).doc(id).delete();

}


public GetData2(path: string)
{
     //return this.db.collection(path).get();
    //console.log(path);

     //let res = this.db.collection(path).get();

    /* res.subscribe( (doc)=>
     {
       console.log(">>>>>>>>>>", doc.docs[0].data());

     });
*/
     //this.firestore.collection("coffeeOrders").snapshotChanges();

     
    return this.db.collection(path).snapshotChanges();
    //return this.db.collection(path).get();

}

public GetDataPromesa(path: string)
{
  return this.db.collection(path);
}

public GetData(path: string)
{
  
  //this.db.firestore.settings.

  return this.db.firestore.collection(path);

  //return this.db.collection(path).get();
  //return this.db.collection(path).get();
  
/*
  this.db.firestore.collection(path).get()
  .then((snapshot) => {
    snapshot.forEach((doc) => {
      console.log(doc.id, '=>', doc.data());
    });
  })
  .catch((err) => {
    console.log('Error obteniendo documentos', err);
  });
  */
}


public GetDataObs(path: string)
{
  return this.db.collection(path).get();
  //return this.db.firestore.collection(path);
 
}


public GetChatMessagesObs(path: string)
{
  return this.db.collection<IChatMessage>(path).get();
}



public GetDocumentObs(path: string)
{
  //return this.db.collection(path);
  return this.db.firestore.collection(path);
}


public GetData3(path: string)
{
  return this.db.firestore.collection(path).doc("Votaciones");
}

}
