import { Component, ViewChild } from '@angular/core';
import { DataService } from '../../services/data/data.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { IPersonaRegistro, IPreregistro, IRegistroEntry} from '../../Models/Poderes/ipersona-registro';
import { Cypher } from '../../Logic/Cypher';
import Swal from 'sweetalert2';
import {environment} from  '../../../environments/environment';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { FileLoaderComponent } from '../../dashboard/custom/fileLoader/file-loader/file-loader.component';
import { IInmueble, IRegistroQuorum } from '../../Models/Vote/Votacion';

@Component({
  selector: 'register-entry',
  templateUrl: 'register-entry.component.html'
})
export class RegisterEntryComponent {

  formaPreregistro: FormGroup;

  @ViewChild(FileLoaderComponent) loaderFile: FileLoaderComponent;

  //personaRegistro:PersonaRegistro;
   personaRegistro:String;

   inmuebleRegistro:IRegistroEntry;
   
   _htmlContent:string;
   preregistro: IPreregistro;


  AsambleaId:string;
  krypto:Cypher;
  registroState:boolean;
  hasQueryFailed:boolean;
  dataValidMessage:string;

  constructor( private fb: FormBuilder, private _dataService : DataService, private http:HttpClient) {
    
    this.crearFormulario(); 

    this.krypto =  new Cypher();
  
   // this.AsambleaId = "";

    //if(this.AsambleaId===undefined)
   // {
        this.AsambleaId = "Pinarsubah";   // =localStorage.getItem("Asamblea");
   // }

    this.registroState = false;
    this.hasQueryFailed = false;

    this.dataValidMessage = "";

    this._htmlContent = '<p>Probando envio desde api:  {Token} de entrada</p>';

  }

  crearFormulario(){

    this.formaPreregistro =  this.fb.group(
      {
          inmueble:  ['',  Validators.required]
         ,nombre:  ['']
         ,keyPad:  ['',  Validators.required]
      });
 }

   get inmuebleInvalido()
   {
    this.dataValidMessage = "Ingrese el inmueble..";  
    return this.formaPreregistro.get('inmueble').invalid && this.formaPreregistro.get('inmueble').touched;
   }

   get keypadInvalido()
   {
      this.dataValidMessage = "Ingrese el control..";  
      return this.formaPreregistro.get('keyPad').invalid && this.formaPreregistro.get('keyPad').touched;
   }

   get queryFail()
   {
      return this.hasQueryFailed;
   }

   
   get sumaryAlert()
   {
      let result = false;

      result = this.inmuebleInvalido || this.keypadInvalido;

     return result
   }

   get registroOK()
   {
     return this.registroState;
   }


   Limpiar(){

    this.formaPreregistro.controls["inmueble"].setValue("");
    this.formaPreregistro.controls["nombre"].setValue("");
    this.formaPreregistro.controls["keyPad"].setValue("");
    this.hasQueryFailed = false;
    this.registroState =false;
    this.dataValidMessage = "";  

   }


   Consultar(){
      this.hasQueryFailed = false;
      this.registroState =false;
      this.formaPreregistro.controls["nombre"].setValue("");
      this.formaPreregistro.controls["keyPad"].setValue("");
  

        this.inmuebleRegistro = this.formaPreregistro.value as IRegistroEntry;

        if(this.inmuebleRegistro.inmueble==="")
        {
          //this.hasQueryFailed = true;    
          return;
        }

        console.log("personaRegistro:::::: --- -",this.inmuebleRegistro);

        let path  = this.AsambleaId+"/Inmueble/Lista/";

        let refDoc = this._dataService.Create(path);

        refDoc.doc(this.inmuebleRegistro.inmueble).get()
        .then((doki)=>
        { 
              if(doki.exists){  console.log(doki.data()); 

                let control =((doki.data()) as IInmueble).keyPad;

                this.formaPreregistro.controls['keyPad'].setValue(control);
                this.formaPreregistro.controls['nombre'].setValue(((doki.data()) as IInmueble).propietario);
                //this.registroState = true;
              }
              else{
                console.log("Inmueble no encontrado...!!");
                 this.registroState = false;
                 this.hasQueryFailed = true;
              }
        })
        .catch((error)=>{
                console.log(error);
        })

   }



   /*
  async SendmailApi(_email:string )
   { 

      await this.GetDataAsamblea();

       this.InjectTemplateData(); 

        var options = {
          method: 'POST',
          url:   environment.InfosolConfig.sendMailMethod, //'https://api.sendinblue.com/v3/smtp/email',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'api-key':   environment.InfosolConfig.sendAPIKEY.toString()  // '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'
          },
          body: {
            //sender: {name: 'Asamblea.SPB', email: 'haroldvilla@gmail.com'},
            sender: {name: 'Asamblea.SPB', email: 'info@infosolcolombia.com'},
            to: [{name: this.personaRegistro.nombre , email: _email.toString()}],
            tags: ['spb'],
            htmlContent: this._htmlContent,//'<p>Probando envio desde api</p>',
            textContent: 'es necesario?',
            subject: 'Pre-registro Asamblea SPB',

          },
          json: true
        };
        

        //this.http.request("POST", "https://api.sendinblue.com/v3/smtp/email",options)
        this.http.request("POST", environment.InfosolConfig.sendMailMethod,options)
          .subscribe((resp)=>{
                console.log(resp);
          });
   }


   InjectTemplateData()
   {
        this._htmlContent = this._htmlContent.replace("{Token}",this.personaRegistro.documento);
        this._htmlContent = this._htmlContent.replace("{Name}",this.personaRegistro.nombre);
   }

*/   
 async GetDataAsamblea()
  {
      let path = this.AsambleaId;

      let refDoc = this._dataService.GetData(path).doc("Inmueble/Lista");

     await refDoc.get()
      .then((resp)=>{

            this.preregistro =resp.data() as IPreregistro;
            this._htmlContent = this.preregistro.mailTemplate.trim();
            console.log(this.preregistro);

      })
      .catch((error)=>{
            console.log("error:::error::::",error);
      })


  }


  async SetControlVote(_inmuebleCodigo:string, _controlId:string)
  {
    let path = this.AsambleaId.toString().trim()+"/Inmueble/Lista/";
    var inmuebleRef = this._dataService.GetData(path);
    
    console.log("inmue:: ", _inmuebleCodigo);
    console.log("ctrl:: ", _controlId);

    _inmuebleCodigo  =_inmuebleCodigo.toString().trim();

   await  inmuebleRef.doc(_inmuebleCodigo).update({ keyPad:_controlId })
   .then((resp)=>{
                  console.log("Ingreso OK",resp);
    })
   .catch((error)=>{
  
        console.log("Error de registro en asamblea ",error);

    })
  }


  async RegistrarEnQuorum(_inmueble:IInmueble, _keyPad:string)
  {

    let registro:IRegistroQuorum = {
    $Key : _inmueble.apto,
    asistentes : parseInt(_inmueble.totalCoeficiente),
    inmueble :_inmueble.apto,
    coeficiente :_inmueble.coeficiente,
    fecha : Date.now().toString(),
    nombre : _inmueble.propietario,
    puedeVotar : _inmueble.puedeVotar

    };

    console.log("registro::  ", registro);

    let path  = this.AsambleaId+"/QuorumActual/RegistroQuorum/";

    let refDoc = this._dataService.Create(path);

    refDoc.doc(registro.inmueble).set(Object.assign({},registro))
    .then( async (resp)=>
    { 
          console.log("QQ: ",resp);
          await this.SetControlVote(registro.inmueble,_keyPad);


          //Swal.fire('Registro','Registro de ingreso a la asamblea completado','success');
    })
    .catch((error)=>{
            console.log(error);
    })

  }


  CrearRegistro()
  {
    this.hasQueryFailed = false;
    this.registroState =false;
    this.formaPreregistro.controls["nombre"].setValue("");

    if( this.formaPreregistro.invalid )
    {

      return Object.values( this.formaPreregistro.controls ).forEach(control=>
        {
            control.markAllAsTouched();
        })
    }

    if(this.sumaryAlert===false){

        this.inmuebleRegistro = this.formaPreregistro.value as IRegistroEntry;

        console.log("personaRegistro:::::: --- -",this.inmuebleRegistro);

        let path  = this.AsambleaId+"/Inmueble/Lista/";

        let refDoc = this._dataService.Create(path);

        refDoc.doc(this.inmuebleRegistro.inmueble).get()
        .then((doki)=>
        { 
              if(doki.exists){  console.log(doki.data()); 

                
                //let control = ((doki.data()) as IInmueble).keyPad;
                //this.formaPreregistro.controls['keypad'].setValue(control);
                this.formaPreregistro.controls['nombre'].setValue(((doki.data()) as IInmueble).propietario);
                this.registroState = true;

                let _inmueble = doki.data() as IInmueble;
                this.RegistrarEnQuorum(_inmueble, this.inmuebleRegistro.keyPad);
              }
              else{
                console.log("Inmueble no encontrado...!!");
                 this.registroState = false;
                 this.hasQueryFailed = true;
              }

              //Swal.fire('Registro','Registro de ingreso a la asamblea completado','success');
        })
        .catch((error)=>{
                console.log(error);
        })
    }


        
    }


}
