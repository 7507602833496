import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IAsambleaActiva } from '../../Models/Vote/Votacion';
import { DataService } from '../data/data.service';
import { HttpClient } from '@angular/common/http';
import { SendinblueAPI } from './../../services/sendinblue/sendinblueAPI';

@Injectable({
  providedIn: 'root'
})
export class SendinblueService {

   asambleaActiva:IAsambleaActiva;

   _htmlTemplate:string;  // template to build htmlContent
   _htmlContent:string;   ///  html body with data
   _subject:string;
   _method:string;
   _headers:any;
   _options:any;

  constructor(private _dataService:DataService,private http:HttpClient) {  
    this._method = "POST";
    this._subject= "";
    this._headers = {   accept: 'application/json', 
    'content-type': 'application/json',
    'api-key': SendinblueAPI.APK
  };
  }

  SetOptions(
         _method:string
        ,_subject:string
        ,_emailTo:string
        ,_nameTo:string
        ,_textContent:string
        ,_rqMethod:string
    )
  {
    
 

   this._options = {
                    method: this._method,
                    url:  SendinblueAPI.TransactMail.sendMail,
                    headers: this._headers,
                    body: {
                        sender: {name: this.asambleaActiva.senderName , email:this.asambleaActiva.emailSender },
                        to: [{name: _nameTo , email: _emailTo}],
                        tags: [this.asambleaActiva.$Key],
                        htmlContent: this._htmlContent,
                        textContent: _textContent,
                        subject:  this._subject
                    },
                        json: true
                    };
  }


  SendMail(_nameTo:string, _emailTo:string, _subject:string)
  {

    let options = {
      method: this._method,
      url:  SendinblueAPI.TransactMail.sendMail,
      headers: this._headers,
      body: {
          sender: {name: this.asambleaActiva.senderName , email:this.asambleaActiva.emailSender },
          to: [{name: _nameTo , email: _emailTo}],
          tags: [this.asambleaActiva.$Key],
          htmlContent: this._htmlContent,
          textContent: "",
          subject:  this._subject
      },
          json: true
      };

    this.http.request<any>(this._method, SendinblueAPI.TransactMail.sendMail, options)
      .subscribe((resp)=>{
            console.log(resp);
      });

  }
  
  CreateContact(_nombre:string,_email:string,listaId:number,_empresa:string)
  {
    let options = {
      method: 'POST',
      url:  SendinblueAPI.Contacts.createContact,
      headers: this._headers,
      body: { attributes: {Empresa: _empresa, Nombre: _nombre },listIds: [listaId],
        updateEnabled: true, email: _email
      },
      json: true
    };

    this.http.request<any>("POST", SendinblueAPI.TransactMail.sendMail, options)
      .subscribe((resp)=>{
            console.log(resp);
      });
  }


  CreateContactDOI()
  {

  }

  GetHtmlTemplate(templateId:string)
  {
      let path = this.asambleaActiva.$Key+"/"+templateId;

      let refDoc = this._dataService.GetData(path)
      refDoc.get()
      .then((resp)=>{

            console.log(resp);

      }).catch((error)=>{
            console.log(error);
      })
  }


}
