import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accionaria',
  templateUrl: './accionaria.component.html',
  styleUrls: ['./accionaria.component.css']
})
export class AccionariaComponent implements OnInit {

   Nombre:string;
   Participacion:number;
   Acciones:number;
   Identificacion:string;
   
  constructor() { 
     
    this.getDataForUser();

  }

   private getDataForUser()
   {
    this.Nombre = "HAROLD VILLA ALVAREZ";
    this.Participacion=0.23658925253;
    this.Acciones= 1520;
    this.Identificacion = "88189737";
   }


  ngOnInit() {
  }

}
