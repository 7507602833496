import { Injectable, ɵNOT_FOUND_CHECK_ONLY_ELEMENT_INJECTOR } from '@angular/core';
import { AngularFireDatabase,AngularFireList } from '@angular/fire/database';
import {Message} from './../../Models/chat/message';

/**
 * 
 Eastern Standard Time http://worldclockapi.com/api/json/est/now

Coordinated Universal Time http://worldclockapi.com/api/json/utc/now

Also supports JSONP
Central European Standard Time http://worldclockapi.com/api/jsonp/cet/now?callback=mycallback
 * 
 * 
*/


@Injectable({
  providedIn: 'root'
})
export class ChatService {

  messageList: AngularFireList<any>;

  defaultLong:number = 20;

  nameEntry:string="chatasamblea";

  constructor(private firebase : AngularFireDatabase) { }


  public AjustarTexto(cadena: string, longEsperada:number)
  {
    this.defaultLong = longEsperada;
    let newCadena = "";

    if(longEsperada <= 0 ){longEsperada=20}

    if(longEsperada >  cadena.length){ longEsperada = cadena.length }

    let fragments = Math.trunc( cadena.length/longEsperada) ;

    //fragments < 1 ? 1 : fragments;
    if(fragments < 1){fragments =1;  } else {fragments++; }

    let currLine = '';

    let x = 1; // comodín por causa de  
    console.log(cadena);
    console.log("frag:");
    console.log(fragments);
    for(var i:number=0; i<fragments; i++)
    {
      console.log("init");
      console.log(longEsperada);
      console.log(i);
      currLine = cadena.slice( i*longEsperada,(longEsperada + (i*longEsperada) ) - x );
 
      newCadena = newCadena + currLine + '\n';
      //currLine = cadena.slice(currLine.length, cadena.length-1);
      if((cadena.length - currLine.length-1)<0){  longEsperada  =  cadena.length; }
      if((cadena.length - currLine.length-1)===0){longEsperada  =   this.defaultLong; }
      if((cadena.length - currLine.length-1)>0 && (cadena.length - currLine.length-1) <= this.defaultLong )
      {
        longEsperada  =  cadena.length - currLine.length-0;
      }
      console.log("new");
      console.log(longEsperada);
     
      //currLine = cadena.slice( i*longEsperada,(longEsperada + (i*longEsperada) ) -0);

     // newCadena = newCadena + cadena.slice( i*longEsperada,(longEsperada + (i*longEsperada) ) -0) + '\n';

    }

    return newCadena;
  
  }

 
   getMessages()
   {
       this.messageList =  this.firebase.list(this.nameEntry); 
       console.log("getMessages");
      return this.messageList;
   }
   
   insertMessage(msg:Message)
   { /// admin.database.ServerValue.TIMESTAMP
       this.messageList.push(
         {
          name: msg.senderName, 
          documento: msg.documento,
          timeStamp: Date, // current date time
          message: msg.mensaje
         }
       );
   }


    updateMessage(msg:Message)
    {
      this.messageList.update(msg.$key,
        {
         name: msg.senderName, 
         documento: msg.documento,
         timeStamp: Date, // current date time
         message: msg.mensaje
        }
      );

    }

    deleteMessage($key:string)
    {
      this.messageList.remove($key);

    }


}
