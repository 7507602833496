import { INavData } from '@coreui/angular';

export class AccessAuth
{

    perfil:string;

    AccessAuth()
    {
      console.log("AccessAuth::::");

      this.perfil = localStorage.getItem("Perfil");
    }

    get _404Page()
    {
      return '404';
    }
    public _GetSalirPage()
    {
      return 'Salir';
    }

    public _GetAsistenciaLink()
    {
      return 'Asistencia';
    }

}

export const navItems: INavData[] = [
  {
    name: 'Inicio',
    url: '#',
    icon: 'icon-grid',
    badge: {
      variant: 'info',
      text: ''
    }
  },
  {
    name: 'Votaciones',
    //url:"../../base/votacionenlinea",
    //url:"https://cartilladigital-9cbb5.web.app",
    url:"/base/votacionenlinea",
    icon: 'icon-drop'
  },
  {
    name: 'Cartilla',
    //url:"../../base/votacionenlinea",
    //url:"https://cartilladigital-9cbb5.web.app",
    url:"/base/tables",
    icon: 'icon-drop'
  },
  {
    name: 'Registrados',
    url:"/base/tabs",
    icon: 'icon-drop'
  }
  
  /*,
  {
    name: 'Control de Registro',
    url: '/base/verificar-poder-SPB',
    icon: 'icon-drop'
  }*/
  /*,{
    //title:  true,
    icon: 'icon-exit', 
    url: 'Salir',
    name: 'Salir'
  }*/
  /*,
  {
    title: true,
    name: 'Reportes'
  },
  {
    name: 'Asistencia',
    url: '/base/tables',
    icon: 'icon-drop'
  },
  {
    name: 'Votantes',
    url: '/base/noVotes',
    icon: 'icon-drop'
  },
  {
    name: 'Solicitudes',
    url: '/base/solicitud',
    icon: 'icon-drop'
  },
  {
    name: 'Inmuebles',
    url: '/base/inmuebles',
    icon: 'icon-drop'
  },
   {
    name: 'Registro',
    url: '/base/inmuebles',
    icon: 'icon-drop'
  },
  {
    name: '',
    url: '#',
    icon: '.'
  },
 {
    name: '',
    url: '/#',
    icon: ''
  },
  {
    title: false,
    name: ''
  },
 {
    children: []
  },
  {
    divider: true
  }*/
];

export const navItems2: INavData[] = [
  {
    name: 'Inicio',
    url: '#',
    icon: 'icon-grid',
    badge: {
      variant: 'info',
      text: ''
    }
  }/*,
  {
    name: 'Registrados',
    url:"/base/tabs",
    icon: 'icon-drop'
  }*/
];

export const navItems3: INavData[] = [
  {
    name: 'Inicio',
    //url: '/dashboard',
    url: '#',
    //icon: 'icon-speedometer',
    //name: 'Inicio',
    //url: '#',
    icon: 'icon-grid',
    badge: {
      variant: 'info',
      text: ''
      //text: '| start |'
    }
  },
  {
    title: true,
    name: 'Reportes'
  },
  {
    //name: 'Votaciones',
   //url: '/votaciones',
    //name: '',
    //name: 'Asistencia',
    name: 'Asistencia',
    url: '/base/tables',
    icon: 'icon-drop'
  },
  {
    name: 'Votantes',
    url: '/base/noVotes',
    icon: 'icon-drop'
  },
  {
    name: 'Solicitudes',
    url: '/base/solicitud',
    icon: 'icon-drop'
  },
  {
    name: 'Inmuebles',
    url: '/base/inmuebles',
    icon: 'icon-drop'
  },
  {
    name: 'Votacion en linea',
    url: '/base/votacionenlinea',
    icon: 'icon-drop'
  },
  {
    name: 'Verificar PODERES',
    url: '/base/verificar-poder-SPB',
    icon: 'icon-drop'
  },
   /*{
    name: 'Registro',
    url: '/base/inmuebles',
    icon: 'icon-drop'
  },*/
  {
   //name: 'Colors',
    //url: '/theme/colors',
    //icon: 'icon-drop'
    name: '',
    url: '#',
    icon: '.'
  },
 {
    /*name: 'Typografia',
    url: '/theme/typography',
    icon: 'icon-pencil'*/
    name: '',
    url: '/#',
    icon: ''
  },
  {
    //title: true,
    //name: 'Components'
    title: false,
    name: ''
  },
 {
    //name: 'Base',
    //url: '/base',

    //name: '',
    //url: '#',
    //icon: 'icon-puzzle',
    //icon: 'icon-puzzle',
    children: [
      /*{
        //name: 'Votaciones',
        //url: '/votaciones',
        name: 'Reporte',
        url: '/base/tables',
        icon: 'icon-puzzle'
      },
      {
        name: 'Cards',
        url: '/base/cards',
        icon: 'icon-puzzle'
      }
      ,{
        name: 'Carousels',
        url: '/base/carousels',
        icon: 'icon-puzzle'
      },
      {
        name: 'Collapses',
        url: '/base/collapses',
        icon: 'icon-puzzle'
      },
      {
        name: 'Forms',
        url: '/base/forms',
        icon: 'icon-puzzle'
      },
      {
        name: 'Pagination',
        url: '/base/paginations',
        icon: 'icon-puzzle'
      },
      {
        name: 'Popovers',
        url: '/base/popovers',
        icon: 'icon-puzzle'
      },
      {
        name: 'Progress',
        url: '/base/progress',
        icon: 'icon-puzzle'
      },
      {
        name: 'Switches',
        url: '/base/switches',
        icon: 'icon-puzzle'
      },
      {
        name: 'Reporte',
        url: '/base/tables',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tabs',
        url: '/base/tabs',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tooltips',
        url: '/base/tooltips',
        icon: 'icon-puzzle'
      }*/
    ]
  }/*,
  {
    //name: 'Buttons',
    //url: '/buttons',
    //icon: 'icon-cursor',
    
   name: '',
   url: '#',
   icon: '',
    children: [
      {
        name: 'Buttons',
        url: '/buttons/buttons',
        icon: 'icon-cursor'
      },
      {
        name: 'Dropdowns',
        url: '/buttons/dropdowns',
        icon: 'icon-cursor'
      },
      {
        name: 'Brand Buttons',
        url: '/buttons/brand-buttons',
        icon: 'icon-cursor'
      }
    ]
  },
 {
    name: 'Charts',
    url: '/charts',
    icon: 'icon-pie-chart'
  },
 {
    name: 'Icons',
    url: '/icons',
    icon: 'icon-star',
    children: [
      {
        name: 'CoreUI Icons',
        url: '/icons/coreui-icons',
        icon: 'icon-star',
        badge: {
          variant: 'success',
          text: 'NEW'
        }
      },
      {
        name: 'Flags',
        url: '/icons/flags',
        icon: 'icon-star'
      },
      {
        name: 'Font Awesome',
        url: '/icons/font-awesome',
        icon: 'icon-star',
        badge: {
          variant: 'secondary',
          text: '4.7'
        }
      },
      {
        name: 'Simple Line Icons',
        url: '/icons/simple-line-icons',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'Notifications',
    url: '/notifications',
    icon: 'icon-bell',
    children: [
      {
        name: 'Alerts',
        url: '/notifications/alerts',
        icon: 'icon-bell'
      },
      {
        name: 'Badges',
        url: '/notifications/badges',
        icon: 'icon-bell'
      },
      {
        name: 'Modals',
        url: '/notifications/modals',
        icon: 'icon-bell'
      }
    ]
  }*/,
  /*{
    name: 'Widgets',
    url: '/widgets',
    icon: 'icon-calculator',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },*/
  {
    divider: true
  }
 /*, {
    title: true,
    name: 'Extras',
  },
  {
    name: 'Pages',
    url: '/pages',
    icon: 'icon-star',
    children: [
      {
        name: 'Login',
        url: '/login',
        icon: 'icon-star'
      },
      {
        name: 'Register',
        url: '/register',
        icon: 'icon-star'
      },
      {
        name: 'Error 404',
        url: '/404',
        icon: 'icon-star'
      },
      {
        name: 'Error 500',
        url: '/500',
        icon: 'icon-star'
      }
    ]
  },*/
 /*{
    name: 'Disabled',
    url: '/dashboard',
    icon: 'icon-ban',
    badge: {
      variant: 'secondary',
      text: 'NEW'
    },
    attributes: { disabled: true },
  }
  ,{
    name: 'Download CoreUI',
    url: 'http://coreui.io/angular/',
    icon: 'icon-cloud-download',
    class: 'mt-auto',
    variant: 'success',
    attributes: { target: '_blank', rel: 'noopener',disabled:true }
  },
  {
    name: 'Try CoreUI PRO',
    url: 'http://coreui.io/pro/angular/',
    icon: 'icon-layers',
    variant: 'danger',
    attributes: { target: '_blank', rel: 'noopener' }
  }*/
];

