import { Component, ViewChild } from '@angular/core';
import { DataService } from '../../services/data/data.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {IPersonaRegistro, IPreregistro, IConsultaAcceso} from '../../Models/Poderes/ipersona-registro';
import {PersonaRegistro} from '../../Models/Poderes/persona-registro';
import { Cypher } from '../../Logic/Cypher';
import Swal from 'sweetalert2';
import {environment} from  '../../../environments/environment';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { FileLoaderComponent } from '../../dashboard/custom/fileLoader/file-loader/file-loader.component';
import { IAsambleaActiva, IInmueble } from '../../Models/Vote/Votacion';

@Component({
 // selector: 'app-dashboard',
  templateUrl: 'consultarAcceso.component.html'
})
export class ConsultaraccesoComponent {

    formaConsultaAcceso : FormGroup;
    personaRegistro     : IPersonaRegistro;
    consultaAcceso      : IConsultaAcceso;
    preregistro         : IPreregistro;
    _htmlContent:string;
  
    AsambleaId:string;
        
    asambleaActiva:IAsambleaActiva;
    inmueble:IInmueble;
    htmlTemplate:String;
    consultaActual:IConsultaAcceso;
    //krypto:Cypher;

  constructor( private fb: FormBuilder, private _dataService : DataService, private http:HttpClient) {
    
    this.crearFormulario(); 

    //this.krypto =  new Cypher();
  
    this.AsambleaId = "ConsultarToken";

    this._htmlContent = '<p>Señor(a) {Name} <br> El token de acceso de su asamblea ha sido enviado a su correo electrónico <br> Cordialmente, <br><br>  Sistema Asamble aVirtual.  </p>';

  }

  crearFormulario(){

    this.formaConsultaAcceso =  this.fb.group(
      {
          nombre:  ['', Validators.required]
          ,email:  ['', Validators.required]
       });
 }

   get nombreInvalido()
   {
      return this.formaConsultaAcceso.get('nombre').invalid && this.formaConsultaAcceso.get('nombre').touched;
   }


   get emailInvalido()
   {
      return (this.formaConsultaAcceso.get('email').invalid && this.formaConsultaAcceso.get('email').touched ) || !this.validateEmail(this.formaConsultaAcceso.get('email').value);

      //return  !this.validateEmail(this.formaConsultaAcceso.get('email').value);
   }

   get sumaryAlert()
   {

      let result = false;

      result = this.nombreInvalido || this.emailInvalido;

     return result
   }


  async SendmailApi(_email:string )
   { 

     // await this.GetDataAsamblea();

       this.InjectTemplateData(); 

        var options = {
          method: 'POST',
          url:   environment.InfosolConfig.sendMailMethod, //'https://api.sendinblue.com/v3/smtp/email',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'api-key':   environment.InfosolConfig.sendAPIKEY.toString()  // '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'
          },
          body: {
            sender: {name: this.asambleaActiva.senderName, email: this.asambleaActiva.emailSender},
            to: [{name: this.inmueble.propietario , email: _email.toString()}],
            tags: [this.asambleaActiva.$Key],
            htmlContent: this._htmlContent,//'<p>Probando envio desde api</p>',
            textContent: 'Informativo',
            subject: this.asambleaActiva.nombreAsamblea,

          },
          json: true
        };
        
          console.log("options::::::", options);

        this.http.request("POST", environment.InfosolConfig.sendMailMethod,options)
          .subscribe((resp)=>{
                console.log(resp);
          });
   }


   InjectTemplateData()
   {
       this._htmlContent = this._htmlContent.replace("{Token}",this.inmueble.token);
       this._htmlContent = this._htmlContent.replace("{Name}",this.inmueble.propietario);
       // this.htmlTemplate  = this.htmlTemplate.replace("{Token}",this.inmueble.token);
       // this.htmlTemplate  = this.htmlTemplate.replace("{Name}",this.inmueble.propietario);
   }


async GetDataAsamblea(_idAsamblea:string){

  let path = _idAsamblea;

  let refDoc = this._dataService.GetData(path).doc("AsambleaActiva");

 await refDoc.get()
  .then((doc)=>{
          this.asambleaActiva = doc.data() as IAsambleaActiva;
  })
  .catch((error)=>{
        console.log("error:::error::::",error);
  });

 // InvitacionTemplate
  let refDocHtml = this._dataService.GetData(path).doc("InvitacionTemplate");

 await refDocHtml.get()
  .then((doc)=>{
       this.htmlTemplate = doc.data().convocatoriaHtml as string;
       this._htmlContent = this.htmlTemplate.toString();
  })
  .catch((error)=>{
        console.log("error:::error::::",error);
  });

}

 async GetDataInmueble(_idAsamblea:string, _codigo:string)
  {
      let path = _idAsamblea+"/Inmueble/Lista";

      console.log(path);

      let refDoc = this._dataService.GetData(path).where("codigo","==",_codigo);

     await refDoc.get()
      .then((resp)=>{
              resp.forEach((doc)=>{
                    this.inmueble = doc.data() as IInmueble;
              });
      })
      .catch((error)=>{
            console.log("error:::error::::",error);
      });

  }

  isRegisterd:boolean;

  async Confirmar(){
    if(this.isRegisterd===true){
      Swal.fire('Asamblea virtual','El enlace de asamblea fué enviado a su email.','success');
    }else{
      Swal.fire('Asamblea virtual','La información suministrada no se encuentra en nuestros registros de asamblea, <br>Por favor, Verifique la información y/o consulte su administrador','warning');
    }
  }

  async Consultar(){
   
    await this.EnviarConsulta();

    //await this.Confirmar();

  }

  validateEmail(_email:string) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   
     // console.log(regularExpression.test(String(_email).toLowerCase()));
    return regularExpression.test(String(_email).toLowerCase());
    
   }


  async EnviarConsulta()
  {
    this.isRegisterd =false;

    if( this.formaConsultaAcceso.invalid )
    {

      return Object.values( this.formaConsultaAcceso.controls ).forEach(control=>
        {
            control.markAllAsTouched();
        })
    }

    if(this.sumaryAlert===false){

        let nombre  = this.formaConsultaAcceso.get('nombre').value;

        let email  = this.formaConsultaAcceso.get('email').value;
      
        let path  = this.AsambleaId;

        let refDoc = this._dataService.Create(path);

        let result =false;

        refDoc.where("email","==",email).where("activo","==",true).get()
        .then((resp)=>
        { 
              
              console.log("doc.data()::doc.data()",resp.docs);

              if(resp.docs.length==0){
                Swal.fire('Asamblea virtual','La información suministrada no se encuentra en registro de asamblea, <br>Por favor, Verifique la información y/o consulte su administrador','warning');
                return;
              }
         
              resp.forEach(async (doc)=>{
                   
                    console.log("doc.data()::doc.data() 2",doc.data());

                    this.consultaActual = doc.data() as IConsultaAcceso;
                    result = true;
                    this.isRegisterd=true;
                    
                    await this.GetDataInmueble(this.consultaActual.asambleaId.toString(),this.consultaActual.identificador);
                    
                    await this.GetDataAsamblea(this.consultaActual.asambleaId.toString());

                    this.SendmailApi( doc.data().email );

                    Swal.fire('Asamblea virtual','El enlace de asamblea fué enviado a su email.','success');
              });

              console.log("pasando por aqui");
        })
        .catch((error)=>{
                console.log(error);
        })

       // console.log("consulta", result);
    }
    
}


}
