import { Component } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataService } from '../../services/data/data.service';

//import { Cypher } from './../../Logic/cypher';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'external-register.component.html'
})
export class ExternalRegisterComponent {

  forma:FormGroup;
  participanteId:string;
  //cypher:Cypher;

  token:string;
  enabledPath:string;

  get tokenInvalido()
  {
     return this.forma.get('token').invalid && this.forma.get('token').touched;
  }


  constructor(private router :Router,
                 private route :ActivatedRoute,  private fb: FormBuilder, private dataSrv : DataService) {
                 //this.cypher =  new Cypher();
                  this.crearFormulario();
                  this.enabledPath = "/login/portal183280620201400inf003x1hjkgt/";
                }

  
  crearFormulario(){


                  this.forma  =  this.fb.group(
                    {
                      token: ['', Validators.required]
                    });
                  }


   EnviarDatos()
   {

        console.log("entry",this.token);


   }

 Login()
 {



  if( this.forma.invalid )
  {

    return Object.values( this.forma.controls ).forEach(control=>
      {
          control.markAllAsTouched();
      })
  }



  this.token  =  this.forma.get('token').value;
   //this.participanteId = this.cypher.FactorDekrypt(this.token).toString();
   this.participanteId = this.token;
   let path  = this.enabledPath + this.participanteId;
  console.log("entry",this.token);  
   console.log("path:::: ",path);

   this.router.navigateByUrl(path);
 }  


 salir()
 {
      this.router.navigateByUrl("#/Salir");
 }


}
