import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'session-ended.component.html'
})
export class SessionEndedComponent {

  fecha:Date;
  AsambleaId:String;
  NombreAsamblea:string;

  constructor() { 
  
    this.fecha= new Date();
    this.NombreAsamblea = "Paseo del Lago";
    this.AsambleaId = localStorage.getItem("Asamblea");
    this.NombreAsamblea =  localStorage.getItem("NombreAsamblea");
    console.log("session:::: ending::::");

    sessionStorage.clear();
    localStorage.clear();
  }

}
