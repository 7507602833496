import {Component, OnInit, OnDestroy } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import {navItems, navItems2, navItems3} from '../../_nav';
import {Message} from './../../Models/chat/message';
import { Subscription } from 'rxjs';

//import {FirebaseDatabase} from  '@angular/fire';
import { ChatService } from './../../services/chat/chat.service';
import { DataService } from './../../services/data/data.service';
import { IInmueble, IQuorumActual, IRegistroQuorum } from '../../Models/Vote/Votacion';
import {  environment  } from './../../../environments/environment';

import {Cypher} from './../../Logic/Cypher';
import { routes } from '../../app.routing';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})

export class DefaultLayoutComponent implements OnInit, OnDestroy {
  
  public sidebarMinimized = false;

  public navItems = navItems;
  public navItemsJunta = navItems3;  

  public senderName:string = "";
  
  messageList: Message[];

  messageListJust: Message[];

  messageListDB : any[];

  public Inmueble: IInmueble;

  public AsambleaId:String; // Identidicador de la asamblea
  public ParticipanteId:String; // Identidicador del participante


  //public QuaorumBean: IQuorumActual
  public QuorumActual:number=0;
  public Asistentes:number=0;
  public LastUpdated:string;
  public HoraActual:string= new Date().toString();

  krypto :Cypher; 

  //public quorumSubs: Subscription;

  public quorumSubs: any;
  quorumCalcSubs: any;
  ListaRegistrados: any[];
  SumaCoeficiente: number;

constructor( private chtSrvce: ChatService, 
    private data: DataService,   
    private router :Router,
    private route :ActivatedRoute
    )
{    

  console.log("DefaultLayoutComponent constructor...", (new Date()) );  

  //console.log("Perfil:::::: ", localStorage.getItem("Perfil").toString() );

  //console.log("ROUTER --- LAYOUT ::::::::",router.url);

  //this.navItems = this.navItemsJunta;
  //this.navItems = this.navItems;
  //this.navItemsJunta = navItems3;  

  //let perfil = localStorage.getItem("Perfil");

  let perfil = localStorage.getItem("Perfil") === undefined ? "true":localStorage.getItem("Perfil").toString();

//  if(!perfil){perfil="true"} else

  //this.navItems = this.navItems;
  this.navItems =   navItems2;

  if(perfil==="true"){
    //this.navItems = navItems3;  ojo 24 de julio 2020
  }

  //this.QuaorumBean.fecha = new Date().toString();
  //this.QuaorumBean.asistentes = 0;
  //this.QuaorumBean.acumulado = 0;

   /*if(toDecript!=null)
   {
     this.ParticipanteId = this.krypto.Decript(toDecript);
   }

   this.AsambleaMapper();

   this.AuthParticipante();

   this.GetDataInmueble();*/

   this.GetDataInmueble();  

}


  async ngOnInit()
  {
              console.log("iniciando layout ****************** :");

              await this.CalcularQuorum2();
  }

  
  ngOnDestroy(): void {
    //throw new Error("Method not implemented.");
    //this.SalidaQuorum();
    this.quorumSubs();
    localStorage.setItem("Layout","saliendo del layout");
    //this.quorumCalcSubs();
  }

  hasPoderes:boolean;
  htmlTablePoderes:string;
  private async GetPoderes(){


    this.htmlTablePoderes = '<table class="table">'+
    '<thead>'+
    '<tr>'+
      '<th scope="col">Inmueble</th>'+
        '<th scope="col">Nombre</th>'+
        '<th scope="col">Coeficiente</th>'+
        '</tr>'+
      '</thead>'+
    '<tbody>';
    /*
    +
    '<tr>'+
      '<th scope="row">1</th>'+
        '<td>Mark</td>'+
        '<td>3.0</td>'+
        '</tr>'+
      '<tr>'+
    '<th scope="row">2</th>'+
      '<td>Jacob</td>'+
      '<td>0.96</td>'+      
      '</tr>'+
    '<tr>'+
      '<th scope="row">3</th>'+
      '<td>Larry</td>'+
      '<td>0.258</td>'+
      '</tr>'+
  ' </tbody>'+
  '</table>';
*/
    this.hasPoderes=false;
    let path = this.AsambleaId+"/Inmueble/Lista/"+this.Inmueble.codigo+"/Poderes";

    console.log("pathpath:pathpath",path);
    let refDoc = this.data.GetData(path);

     await refDoc.get()
      .then((resp)=>{

            if(resp.docs.length>0){
              this.hasPoderes=true;
              //this.htmlTablePoderes =  '<table class="table">';
              resp.forEach((poder)=>
              {
                  console.log(poder.data());

                  this.htmlTablePoderes = this.htmlTablePoderes +
                  '<tr>'+
                   '<th scope="row">'+ poder.data().codigo +'</th>' + 
                   '<td>'+ poder.data().nombre +'</td>' +
                   '<td>'+ poder.data().coeficiente +'</td>' + 
                  '</tr>';

              })

              this.htmlTablePoderes = this.htmlTablePoderes +
              '<tr>'+
               '<th scope="row">'+ this.Inmueble.codigo +'*</th>' + 
               '<td>'+ this.Inmueble.propietario+'</td>' +
               '<td>'+ this.Inmueble.coeficientePropio +'</td>' + 
              '</tr>';

              this.htmlTablePoderes = this.htmlTablePoderes +
              '<tr>'+
               '<th scope="row">'+ 'TOTALES' +'</th>' + 
               '<td> </td>' +
               '<td>'+ this.Inmueble.coeficiente +'</td>' + 
              '</tr>';

              this.htmlTablePoderes = this.htmlTablePoderes + '</tbody></table>';
              
            }else{

              this.hasPoderes=false;
            }

           
      })
      .catch((error)=>{
            console.log(error);
      })


  }

  async ShowPoderes()
  {
      //Swal.fire('Poderes.',this.Inmueble.propietario +'''\n'''+this.Inmueble.propietario,'info')
      let timerInterval = 4000;
      Swal.fire({
        html: '<p>Consultando Información, por favor espere</p>',
        position: 'top-end',
        icon: 'info',
        title: '<strong><u>Poderes</u></strong>',
        showCancelButton: false,
        showConfirmButton: true,
        timer: timerInterval
      });

      await this.GetPoderes();

      if(!this.hasPoderes){
        Swal.fire({
          html: '<p>No tiene poderes registrados</p>',
          position: 'top-end',
          icon: 'info',
          title: '<strong><u>Poderes</u></strong>',
          showCancelButton: false,
          showConfirmButton: true,
          timer: 1500
        });
      }else{
            Swal.fire({
            html:this.htmlTablePoderes,
            position: 'top-end',
            icon: 'info',
            title: '<strong><u>Poderes</u></strong>',
            showCancelButton: false,
            showConfirmButton: true,
            timer: 5000
          });

    }

  }

  public FinalizarSession()
  {
  
    console.log("this.route.root::::: ",this.route.root.outlet);
                               
    this.router.navigateByUrl("/#/Salir");
    //router.navigate(['team', 33, 'user', 11], {relativeTo: route});

  }
 
  public GetDataInmueble()
  {

    this.Inmueble = JSON.parse(localStorage.getItem("Inmueble")) as unknown as IInmueble;
    this.GetQuorumActual();
  
    console.log("layout inmueble...",this.Inmueble);
/*
      //busca localStorage
    this.Inmueble = {$Key :"1101",
    activaCamara:true,  
    apto      :"1101",
    codigo    :"1101",
    coeficiente:2.3,
    documento:"-",
    propietario:"Harold",
    torre:"1",
    totalCoeficiente:2.3,
    estado :"1"
  } as unknown as IInmueble;*/

}


  public async  AuthParticipante()
  {
    try{

      //let codigo =  this.krypto.Decript(this.ParticipanteId.toString());
      let codigo = this.ParticipanteId;
      let inmuebleLista = environment.CollectionPath.InmuebleLista;
      
      let pathParticipante = this.AsambleaId.concat(inmuebleLista)+"/"+codigo;
      console.log("pathParticipante:", pathParticipante );
      
      let partRef =  this.data.GetData(pathParticipante).get();
      //console.log("AuthParticipante codigo:", codigo, "ref; ",partRef );
      
      //this.Auth0 = false;

      await partRef.then((participa)=>{
            console.log(" participa.docs:",participa.docs);
            //this.Auth0 = true;
      });

      console.log("AuthParticipante document: layout", document);

      /*.catch((error)=>{
          console.log(" participa error:",error);
          this.Auth0 = false;
      })*/

  
      //this.Auth0 = true;

    }catch(error)
    {
      //this.Auth0 = false;
      //this.router.navigate(['unauthorized']);  
    }

/*
       await this._dataService.GetData(pathParticipante).get()
        .then((resp)=>{
              console.log(resp);
               
        }).catch((error)=>{
          
          console.log(error);
          this.router.navigate(['unauthorized']);
        });*/

        

  }

  
canShowPHResults()
{
     if(this.AsambleaId.includes("typco")==true)
     {
        return false;
     }else
     {
       return true;
     }
}

  AsambleaMapper()
  {
      if(this.AsambleaId==="paseodellago"){ this.AsambleaId="Infosol"; }
      if(this.AsambleaId==="Torresmayor") { this.AsambleaId="Torresmayor2"; }

      console.log("this.ParticipanteId: ",this.ParticipanteId);

  }

/// Calcula el quorum tomando toda la lista de los registrados
async CalcularQuorum2()
{

    this.AsambleaId = localStorage.getItem("Asamblea");

    let path = this.AsambleaId+"/QuorumActual/RegistroQuorum/";

    let refDoc =  this.data.GetData(path);

   this.quorumSubs = refDoc.onSnapshot((snappy)=>
    {
      this.ListaRegistrados = [];
      this.SumaCoeficiente=0;
      this.QuorumActual = 0;
      this.Asistentes=0;

      let quorumActual =  { acumulado: this.SumaCoeficiente, asistentes:this.Asistentes, fecha: (new Date()).toString() } as IQuorumActual;
          

      snappy.forEach(item => {
                  this.ListaRegistrados.push(item.data() as IRegistroQuorum); 
                  this.SumaCoeficiente += parseFloat((( item.data() as IRegistroQuorum).coeficiente).toString() );
                  //this.Asistentes +=1;
                  let currAsst = 0;
                  currAsst = parseFloat(( item.data() as IRegistroQuorum).coeficiente.toString());
                  if(currAsst<=0){
                    this.Asistentes +=0;
                  }else{
                    this.Asistentes += parseFloat(( item.data() as IRegistroQuorum).asistentes.toString());
                  }

                quorumActual =  { acumulado: this.SumaCoeficiente, asistentes:this.Asistentes, fecha: (new Date()).toString() } as IQuorumActual;
                localStorage.setItem("QuorumActual", JSON.stringify(quorumActual));
                this.QuorumActual= this.SumaCoeficiente;   
          });
         // this.QuorumActual = this.SumaCoeficiente;

          //let quorumActual =  { acumulado: this.SumaCoeficiente, asistentes:this.Asistentes, fecha: (new Date()).toString() } as IQuorumActual;
          
        //  localStorage.setItem("QuorumActual", JSON.stringify(quorumActual));
        //  this.QuorumActual= this.SumaCoeficiente;   

    });
}


async SalidaQuorum(){
  //let path = this.AsambleaId+"/QuorumActual/RegistroQuorum/";
  let path = this.AsambleaId+"/QuorumActual/SalidaQuorum";

  let refDoc = this.data.GetData(path);

  await refDoc.add(Object.assign({},this.Inmueble))
  .then((resp)=>{ console.log(resp);})
  .catch((error)=>{ console.log(error);});

  }




   /// Calcula el quorum tomando toda la lista de los registrados
async CalcularQuorum(){
    //let path = this.AsambleaId+"/QuorumActual/RegistroQuorum/";
    let path = this.AsambleaId+"/QuorumActual/RegistroQuorum/";

    console.log("LAYAOUT PATH ***********",path);

    let refDoc = this.data.GetDataObs(path);

    this.quorumCalcSubs = await refDoc.subscribe((resp)=>{
      this.ListaRegistrados = [];
      this.SumaCoeficiente=0;
      this.QuorumActual = 0;
      this.Asistentes=0;

      resp.forEach((item)=>{ 
            
            this.ListaRegistrados .push(item.data() as IRegistroQuorum); 
            this.SumaCoeficiente += parseFloat((( item.data() as IRegistroQuorum).coeficiente).toString() );
            //this.QuorumActual= this.SumaCoeficiente;
            this.Asistentes +=1;
            console.log("item.data() : ", item.data() );
          });

          let quorumActual =  { acumulado: this.SumaCoeficiente, asistentes:this.Asistentes, fecha: (new Date()).toString() } as IQuorumActual;
          
          localStorage.setItem("QuorumActual", JSON.stringify(quorumActual));
          this.QuorumActual= this.SumaCoeficiente;   
          console.log("SumaCoeficiente layout: ",this.SumaCoeficiente);
          console.log("Asistentes: layout ",this.Asistentes);
    });
    }
  
public GetQuorumActual()
  {
    //

    /* this.AsambleaId = localStorage.getItem("Asamblea");

      let path  = this.AsambleaId+ "/QuorumActual";

      let qRef = this.data.GetData("Infosol").doc("QuorumActual");
      
      this.quorumSubs = qRef.onSnapshot((doc)=>{
          
          console.log("doc :  ", doc.data());

          this.QuorumActual = doc.data().acumulado;
      })
*/

  /*  let data = JSON.parse(localStorage.getItem("QuorumActual")) as  IQuorumActual;
    this.QuorumActual = data.acumulado;
    this.Asistentes =  data.asistentes;
    this.LastUpdated = data.fecha;*/

  }

public getJustify(cadena: string, longEsperada: number)
{
    
    let result:string = this.chtSrvce.AjustarTexto(cadena, longEsperada);

    return result;

}

public getMensajes($Key:string)
{
    console.log("chat mensajes");
     let listaMsg = this.chtSrvce.getMessages()
        .snapshotChanges()
        .subscribe(item=> 
        {
              this.messageList = [];
              this.messageListJust= [];
              this.messageListDB=[];
              
              item.forEach( element=> 
              {
                  let msg = element.payload.toJSON();
                  msg["$key"] = element.key;
                  //this.messageList.push(msg as Message);
                  this.messageListJust.push(msg as Message);
              });
        });
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
    console.log("this.sidebarMinimized::::: ",this.sidebarMinimized);
  }

}
