import { Component, OnInit, ViewChild } from '@angular/core';
import {CommonToolService} from './../../services/common/common-tool.service';
import {BrowserModule} from '@angular/platform-browser';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {DataService} from './../../services/data/data.service'
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import Swal from 'sweetalert2';

import * as firebase from 'firebase';

import {environment} from './../../../environments/environment';
import { IAsambleaActiva, IInmueble } from '../../Models/Vote/Votacion';
import { IPersonaRegistro, IApoderado, IPreregistro } from '../../Models/Poderes/ipersona-registro';
import {FileLoaderComponent } from './../../dashboard/custom/fileLoader/file-loader/file-loader.component';

import { PreguntassecComponent } from './../../dashboard/custom/preguntas/preguntas-sec.component';

import {SendinblueService} from './../../services/sendinblue/sendinblue.service';

//import {MatRadioModule} from '@angular/material/radio';

@Component({
  //selector: 'app-dashboard',
  templateUrl: 'pre-register.component.html'
})
export class PreregisterComponent {

  @ViewChild(FileLoaderComponent) fileLoader: FileLoaderComponent;
  @ViewChild(PreguntassecComponent) preguntas: PreguntassecComponent;

  formaRegistro:FormGroup;
  formaPoderes:FormGroup;

  hasPoderes:boolean;
  classPoderesView:string="col-md-12 mx-auto";
  classRegisterView:string="col-md-12 mx-auto";

  target: DataTransfer;
  
  selectedDocType:string;
  
  selectedDocTypePoderes:string;

  Representante:string;  // apoderado o representnate

  documento:string;

   docTypeListValue=["CC", "CE", "NIT"];
   
   docTypeListText=["Cedula de Ciudadanía", "Cedula de Extranjería", "Camara de comercio"];

   AsambleaId:String;
   asambleaActiva:IAsambleaActiva;
   tokenRoom:string;  

  constructor(
              private commonTool:CommonToolService, 
              private mydisk:AngularFirestore, 
              private stg:AngularFireStorage, 
              private _dataService:DataService, 
              private http:HttpClient,
              private router :Router,
              private route :ActivatedRoute,
              private deviceService: DeviceDetectorService,
              private _sendinblue : SendinblueService
              ) 
              {

    this.AuthenticateRoom();   

    //this.AsambleaId = "Simulacro";

    this.GetDataAsamblea();

    this.formaRegistro = new FormGroup(
      {
            'nombre': new FormControl('', Validators.required),
            'email': new FormControl('', Validators.required),
            'documento': new FormControl('', Validators.required),
            'tipoDocumento': new FormControl('CC', Validators.required),
            'telefono': new FormControl('', Validators.required),
            //'listaFiles':new FormControl('', Validators.required),
            //'clave': new FormControl('', !Validators.required),  
            //'claveAgain': new FormControl('', Validators.required)
      }
    );

    this.formaPoderes = new FormGroup(
      {
            'nombre': new FormControl('', Validators.required),
            'email': new FormControl('', Validators.required),
            'documento': new FormControl('', Validators.required),
            'tipoDocumento': new FormControl('CC', Validators.required),
            'telefono': new FormControl('', Validators.required)
      }


    );

    this.hasPoderes=false;
    this.selectedDocType = "CC";
    this.selectedDocTypePoderes = "CC";

  }

  Auth0:boolean;
  async GetToken()
  {
        let refDoc = this._dataService.GetData(this.AsambleaId.toString()).doc("AsambleaActiva");

        await refDoc.get().then((resp)=>{

          console.log("Asamblea::::",  resp.data());
          this.asambleaActiva  = (resp.data()) as IAsambleaActiva;

          let _tokenRoom:string   = this.asambleaActiva.tokenRoom;
          let roomId:string       = this.asambleaActiva.$Key;
          let _isActive:boolean   = this.asambleaActiva.preregistroActivo;
          let _apiListId:number   = this.asambleaActiva.onApiListId;

          if(this.tokenRoom === this.asambleaActiva.tokenRoom && 
             this.AsambleaId === this.asambleaActiva.$Key  &&
             this.asambleaActiva.preregistroActivo === true ) { this.Auth0 = true; }        

        })
        .catch((error)=>{
                console.log(error);
        });
  }

  async AuthenticateRoom()
  {
    this.Auth0            = false;
    this.AsambleaId       = this.route.snapshot.paramMap.get("AsambleaId");
    this.tokenRoom        = this.route.snapshot.paramMap.get("token");

    console.log(this.AsambleaId);
    console.log(this.tokenRoom);

    await this.GetToken();

    if( this.Auth0===false ){
      this.tokenRoom = "";
      this.AsambleaId = "";
      this.router.navigateByUrl('500');}

  }


  upDateDocumento(){
    this.documento=this.formaRegistro.get('documento').value;
    console.log("::::::docdoc",this.documento);
  }

  get getSelectedDocType(){
        return  this.selectedDocType==="CC"? this.docTypeListText[0]: (  this.selectedDocType==="NIT"? this.docTypeListText[2] : this.docTypeListText[1]  );
  }

  get getPoderesSelectedDocType(){
        return  this.selectedDocTypePoderes==="CC"? this.docTypeListText[0]: (  this.selectedDocTypePoderes==="NIT"? this.docTypeListText[2] : this.docTypeListText[1]  );
}
  


  AgregarPoder(){  console.log(this.formaPoderes.value);

    let _personaRegistro = this.formaRegistro.value as IPersonaRegistro;
    
    let path = this.AsambleaId+"/Preregistro/Lista/";
      

  }

  Checking()
  {
    this.hasPoderes=!this.hasPoderes;

    this.classPoderesView = this.hasPoderes===true ?  "col-md-6 mx-auto animated fadeIn mt-10":"col-md-12 mx-auto animated fadeIn mt-10";
    this.classRegisterView = this.hasPoderes===true ? "col-md-6 mx-auto animated fadeIn mt-10":"col-md-6 mx-auto animated fadeIn mt-10";

    console.log(this.hasPoderes);
  }


 async RegistrarAccionista()
  {
      let path = this.AsambleaId + "/Preregistro/Lista/";

      let accionista:IPersonaRegistro = this.formaRegistro.value as IPersonaRegistro;

      let apoderado:IApoderado = this.formaPoderes.value as IApoderado;

      accionista.apoderado = apoderado;
      accionista.estado = "Pendiente";
      accionista.usuario = "No-asignado";

     await this.mydisk.collection(path).doc(accionista.documento.trim()).set(Object.assign({}, accionista))
      .then((resp)=>{
        console.log(resp);
        
        console.log("Email::::", this.formaRegistro.get('email').value.toString());  

        this.fileLoader.CargarDocumentos();  

        this.SendmailApi(this.formaRegistro.get('email').value.toString());

        Swal.fire('Preregistro','Pre-registro creado. Una vez aprobado, recibirá un email con las instrucciones a seguir','success');
      })
      .catch((err)=>{
            console.log(accionista);
      });

  }


  //Cambiar a un servicio
 async CrearContactoLista()
 {
        let listaId = 8;
        let accionista=this.formaRegistro.get('nombre').value;
        let empresa = "SPSM";
        let emailAccionista= this.formaRegistro.get('email').value;

        this._sendinblue.CreateContact(accionista,emailAccionista,listaId,empresa);

        let emailApoderado = this.formaPoderes.get('email').value;
        if(emailApoderado!=undefined && emailApoderado!="")
        {
          let apoderado = this.formaPoderes.get('nombre').value;
          this._sendinblue.CreateContact(apoderado,emailApoderado,listaId,empresa);
        }
 }


 async HasAccionistaDocuments(docId:any)
 {

     if(this.fileLoader.HasDocuments()===true)
     {
       return true;
     }

     let result:boolean = false;
     let pathDocs = this.AsambleaId+"/Preregistro/Lista/"+docId.toString()+"/Documentos";  
    
     let docRef = this._dataService.GetData(pathDocs);
     await docRef.get()
      .then((resp)=>{
            if(resp.docs.length>0){result = true;
              this.hasAlreadyFiles =true;
            }
      })
      .catch((error)=>{})

     return result;
 }


  async RegistrarApoderado()
  {
    
    let concedePoder:boolean = (this.formaPoderes.value as IApoderado).documento===undefined ? false:true;

    if(concedePoder===true)
    {   
        let accionista:IPersonaRegistro = this.formaRegistro.value as IPersonaRegistro;

        let path = this.AsambleaId + "/Preregistro/Lista/"+accionista.documento.trim()+"/Documentos/";

        await this.mydisk.collection(path).doc(accionista.documento.trim()).set(Object.assign({}, accionista))
        .then((resp)=>{
          console.log(resp);
        })
        .catch((err)=>{
              console.log(accionista);
        });
    }
  }

   hasAlreadyFiles:boolean;
   hasAcciones:boolean;
   async VerificarAccionista()
  {
     let result:boolean =false;
     this.hasAlreadyFiles = false;
     this.hasAcciones=false;
     let path = this.AsambleaId+"/Inmueble/Lista";
     let accionista:IPersonaRegistro = this.formaRegistro.value as IPersonaRegistro;
     let refDoc = this._dataService.GetData(path).where("documento","==",accionista.documento.trim());
     await refDoc.get()
     .then((resp)=>{
             
              if(resp.docs.length>0)
              {
                result = true;
                this.hasAcciones=true;

                let accioni = (resp.docs[0].data()) as unknown as  IInmueble;

              }
    })
     .catch((error)=>{console.log(error);})


    await this.HasAccionistaDocuments(accionista.documento)
        .then((resp)=>
        {
             this.hasAlreadyFiles = resp;
        })
        .catch((error)=>{console.log(error);});

     return (this.hasAlreadyFiles && result) ? true :false;

  }
   

  async EnviarRegistro(){

    this.ShowAutocloseMessage();

    if( await this.VerificarAccionista()===true)
    {
          this.RegistrarAccionista();
    }

    if(this.hasAcciones===false)
    {
      Swal.fire('Registro Asamblea','La información sministrada no figura en lista de accionsitas','info');
    }else{

      if(this.hasAlreadyFiles===false)
      {
          Swal.fire('Registro Asamblea','Aún no ha subido sus documentos','info');            
      }
    }

  }

  onOptionsSelected(value:string){  this.selectedDocType = value; }

  
  onPoderesOptionsSelected(value:string){  this.selectedDocTypePoderes = value; }

  porcentaje:number=0;
  finalizado:boolean=false;
  OnFileDocumentChange(event: any) 
  {
     //this.commonTool.OnFileChange(event);
     let target = this.commonTool.OnFileUpload(event);
     var file = target.files[0];

     console.log(file);

     let storageRef = this.stg.storage.ref("SPB_TESTING/"+ file.name);

     let task = this.stg.upload("SPB_TESTING/"+ file.name,file);

     task.percentageChanges().subscribe((porcentaje) => {
      this.porcentaje = Math.round(porcentaje);
      if (this.porcentaje == 100) {
        this.finalizado = true;
      }
    });

  }


 OnPowerFileDocumentChange(event: any) {

  //this.target: DataTransfer = <DataTransfer>(event.target);
  this.commonTool.OnFileChange(event);

}

 OnPowerFileDocumentChange1(event: any) {
      this.commonTool.OnFileChange(event);
}


ShowAutocloseMessage()
{
  let timerInterval
Swal.fire({
  title: 'Proceso en progreso',
  html: 'Espere por favor.',
  timer: 2000,
  timerProgressBar: true,
  onBeforeOpen: () => {
    Swal.showLoading()
    timerInterval = setInterval(() => {
      const content = Swal.getContent()
      if (content) {
        const b = content.querySelector('b')
        if (b) {
          b.textContent = Swal.getTimerLeft().toString();
        }
      }
    }, 100)
  },
  onClose: () => {
    clearInterval(timerInterval)
  }
}).then((result) => {
  /* Read more about handling dismissals below */
  if (result.dismiss === Swal.DismissReason.timer) {
    console.log('I was closed by the timer')
  }
});
}


async SendmailApi(_email:string )
{ 

   //await this.GetDataAsamblea();

    this.InjectTemplateData(); 

     var options = {
       method: 'POST',
       url:   environment.InfosolConfig.sendMailMethod, //'https://api.sendinblue.com/v3/smtp/email',
       headers: {
         accept: 'application/json',
         'content-type': 'application/json',
         'api-key':   environment.InfosolConfig.sendAPIKEY.toString()  // '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'
       },
       body: {
         //sender: {name: 'Asamblea.SPB', email: 'haroldvilla@gmail.com'},
         
         //sender: {name: this.asambleaActiva.senderName , email: 'info@infosolcolombia.com'},
         sender: {name: this.asambleaActiva.senderName , email:this.asambleaActiva.emailSender},
         to: [{name: this.formaRegistro.get('nombre').value.trim() , email: _email.toString()}],
         //tags: ['spb'],
         tags: [this.AsambleaId.toString()],
         htmlContent: this._htmlContent,
         textContent: 'Informativo',
         subject:  this._receptionSubject

       },
       json: true
     };
     
     console.log("options::options", options);
     //this.http.request("POST", "https://api.sendinblue.com/v3/smtp/email",options)
     this.http.request("POST", environment.InfosolConfig.sendMailMethod,options)
       .subscribe((resp)=>{
             console.log(resp);
       });
}

_htmlContent:string;
_receptionSubject:string;
_templateSubject:string;
preregistro:IPreregistro;

InjectTemplateData()
{
     //this._htmlContent = this._htmlContent.replace("{Token}",this.formaRegistro.get('documento').value.tirm());
     //this._htmlContent = this._htmlContent.replace("{Name}",this.formaRegistro.get('nombre').value);
     this._htmlContent = this.preregistro.firstMailBody.replace("{Name}",this.formaRegistro.get('nombre').value);
}

async GetDataAsamblea()
{
   let refDoc = this._dataService.GetData(this.AsambleaId.toString()).doc("Preregistro");

  await refDoc.get()
   .then((resp)=>{

         this.preregistro =resp.data() as IPreregistro;
         //this._htmlContent = this.preregistro.firstMailBody.trim();
         this._receptionSubject=  this.preregistro.firstMailSubject.trim();
         this._templateSubject=  this.preregistro.firstMailBody.trim();

         console.log(this.preregistro);

   })
   .catch((error)=>{
         console.log("error:::error::::",error);
   })

   
   let refDocAsamblea = this._dataService.GetData(this.AsambleaId.toString()).doc("AsambleaActiva");

  await refDocAsamblea.get()
   .then((resp)=>{
         this.asambleaActiva =resp.data() as IAsambleaActiva;
   })
   .catch((error)=>{
         console.log("error:::error::::",error);
   });

}

}
