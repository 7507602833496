import { Component, OnInit} from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl  } from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import Swal from 'sweetalert2';
import { DataService } from './../../../services/data/data.service';
import {IPersonaRegistro, IPreregistro} from './../../../Models/Poderes/ipersona-registro';
//import { Cypher } from './../../../Logic/Cypher';
import {PersonaRegistro} from './../../../Models/Poderes/persona-registro';
import {environment} from  './../../../../environments/environment';

@Component({
  selector: 'app-preguntas',
  templateUrl: './preguntas-sec.component.html'
})
export class PreguntassecComponent  implements OnInit {

   formaPreguntas: FormGroup;

   personaRegistro:IPersonaRegistro;
   
   preregistro: IPreregistro;

   AsambleaId:string;
   //krypto:Cypher;

  IsParentEnabled:boolean; 

  constructor(    private fb: FormBuilder, 
                  private _dataService : DataService, 
                  private http:HttpClient) {
    
    console.log("PREGUNTAS");
    //this.crearFormulario(); 

    //this.krypto =  new Cypher();
  
    this.AsambleaId = "Simulacro";

  }
  ngOnInit(): void {
   // throw new Error("Method not implemented.");
  }

   get p1Invalida():boolean{ return true;  }
   get p2Invalida():boolean{ return true;  }
   get p3Invalida():boolean{ return true;  }
   //get p4Invalida():boolean{ return true;  }
   //get p5Invalida():boolean{ return true;  }

   set enableParent(_enalbe:boolean)
   {
      this.IsParentEnabled = true;

      //return this.IsParentEnabled;
   }

   get isParentEnabled():boolean{
      
      return this.IsParentEnabled;
   }

  crearFormulario(){

    /*this.formaPreguntas =  this.fb.group(
      {
        //'preguntas': new FormArray([])
        pregunta1:  ['',  Validators.required]
        ,pregunta2: ['', Validators.required]

      });*/

      /*this.formaPreguntas = new FormGroup({
        'nombre': new FormControl('', Validators.required),
        'email': new FormControl('', [Validators.required, Validators.email]),
        'direcciones': new FormArray([])
      });

      this.AgregarPregunta();*/
 }


  GetPreguntas()
  {

  }


 AgregarPregunta() {

///  this.formaPreguntas.get('preguntas').value;
/*
  console.log(this.formaPreguntas);

        (this.formaPreguntas['preguntas']).push(
          new FormGroup(
            {
                'direccion': new FormControl('', Validators.required),
                'telefono': new FormControl('', Validators.required)
            }
            )
        );*/
}

EnviarRespuestas(){  console.log("Respuestas enviadas");}

get nombreInvalido()
{
  return false;
  // return this.formaPreguntas.get('respuesta').invalid && this.formaPreguntas.get('respuesta').touched;
}

   
 async GetPreregistro()
  {
     /* let path = this.AsambleaId;

      let refDoc = this._dataService.GetData(path).doc("Preregistro");

     await refDoc.get()
      .then((resp)=>{

            this.preregistro =resp.data() as IPreregistro;
            //this._htmlContent = this.preregistro.mailTemplate.trim();
            console.log(this.preregistro);

      })
      .catch((error)=>{
            console.log("error:::error::::",error);
      })
*/

  }




}
