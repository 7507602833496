import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask  } from '@angular/fire/storage';
import {FileItem} from './../../services/common/filre-item';
import * as firebase from 'firebase';
import { snapshotChanges } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { IDocumento } from '../../Models/Poderes/ipersona-registro';



@Injectable({
  providedIn: 'root'
})
export class FbStorageService {


    private FILES_FOLDER:string;

    private AsambleaId:string="Simulacro";

    private documento:string;


  constructor(private fbDisk:AngularFirestore, private storage:AngularFireStorage) { 
      this.FILES_FOLDER = this.AsambleaId+"/documentos/213";
  }

  SalvarDocumentosFirebase2(documentos: FileItem[]) //2
  {
    console.log("Lita de archivos cargados...:", documentos.length);  
    console.log(documentos);

    const storageRef = this.storage.ref(this.FILES_FOLDER);

    for(const  item of documentos){

         item.upLoading = true;

         console.log("itemitemitem::::", item);

         if(item.progreso <=100) { continue;}

         const uploadTask = storageRef.child(`${this.FILES_FOLDER}/${item.nombreArchivo}`)
         .put(item.archivo);

/*
         uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED),
              (snapshotChanges) => 
                item.progreso = (snapshotChanges.bytesTransferred/snapshotChanges.totalBytes) * 100, 
              (error)=>console.error("error al subir", error),
              ()=>{
                console.log("Imagen cargada correctamente");
                item.url = uploadTask.snapshot.downloadURL;
                item.upLoading = false;
                this.SaveUrlDocument({nombre: item.nombreArchivo,url: item.url,size:5}
                );                
              }
              */
    }
  }

  SalvarDocumentosFirebase01(documentos: FileItem[]) //01
  {
    console.log("Lita de archivos cargados...:", documentos);  

     for(const item of documentos)
     {
      let task = this.storage.upload("SPB_TESTING/"+ item.nombreArchivo,item.archivo);
      
      
      task.task.on(firebase.storage.TaskEvent.STATE_CHANGED),
              (snapshotChanges) => 
                item.progreso = (snapshotChanges.bytesTransferred/snapshotChanges.totalBytes) * 100, 
              (error)=>console.error("error al subir", error),
              ()=>{
                console.log("Imagen cargada correctamente");
                item.url = task.task.snapshot.downloadURL;
                console.log(item.url);
                item.upLoading = false;
                //this.SaveUrlDocument({nombre: item.nombreArchivo,url: item.url, size:1});                
              }

/*
      task.task.on(firebase.storage.TaskEvent.STATE_CHANGED),
      (snapshotChanges) => 
        item.progreso = (snapshotChanges.bytesTransferred/snapshotChanges.totalBytes) * 100, 
      (error)=>console.error("error al subir", error),
      ()=>{
        console.log("Imagen cargada correctamente");
        item.url = uploadTask.snapshot.downloadURL;
        item.upLoading = false;
        this.SaveUrlDocument({nombre: item.nombreArchivo,url: item.url}
        );                
      }*/


      


    
        let unsubscriber=  task.percentageChanges().subscribe((porcentaje) => {
        item.progreso = Math.round(porcentaje);
        if (item.progreso == 100) 
        {
           item.upLoading = false; 
           
           console.log(":::::porcentaje::::",porcentaje);
           console.log(":::::url::::",task.task.snapshot.downloadURL);
           unsubscriber.unsubscribe();

           task.then((doc)=>{
            console.log(doc.downloadURL);
          });
    

           //this.SaveUrlDocument({nombre: item.nombreArchivo,url: item.url})
        }
       
     });

     }
     
    
  }
  
 async SalvarDocumentosFirebase0(documentos: FileItem[]) // 3
  {
    console.log("Lita de archivos cargados...:", documentos);  

     for(const item of documentos)
     {
      let task = this.storage.upload("SPB_TESTING/"+ item.nombreArchivo,item.archivo);

/*
      task.task.on(firebase.storage.TaskEvent.STATE_CHANGED),
      (snapshotChanges) => 
        item.progreso = (snapshotChanges.bytesTransferred/snapshotChanges.totalBytes) * 100, 
      (error)=>console.error("error al subir", error),
      ()=>{
        console.log("Imagen cargada correctamente");
        item.url = uploadTask.snapshot.downloadURL;
        item.upLoading = false;
        this.SaveUrlDocument({nombre: item.nombreArchivo,url: item.url}
        );                
      }*/


    
        let unsubscriber=  task.percentageChanges().subscribe((porcentaje) => {
        item.progreso = Math.round(porcentaje);
        if (item.progreso == 100) 
        {
           item.upLoading = false; 
           
           console.log(":::::porcentaje::::",porcentaje);
           console.log(":::::url::::",task.task.snapshot.downloadURL);
           unsubscriber.unsubscribe();

           task.then((doc)=>{
            console.log(doc.downloadURL);
          });
    

           //this.SaveUrlDocument({nombre: item.nombreArchivo,url: item.url})
        }
       
     });

     }
     
    
  }

  downloadURL: Observable<string>;
  SalvarDocumentosFirebase(documentos: FileItem[], _documento:string)
  {
    console.log("Lita de archivos cargados...:", documentos);  
    this.documento = _documento;
    //const storageRef = this.storage.ref(this.FILES_FOLDER);
    //let target = this.commonTool.OnFileUpload(event);
     //var file = target.files[0];
     //storageRef = this.stg.storage.ref("SPB_TESTING/"+ file.name);
     for(const item of documentos)
     {
       item.nombreArchivo = _documento +"_"+ item.nombreArchivo;
       const storageRef = this.storage.ref("SPB_TESTING/"+ item.nombreArchivo);
       let task = this.storage.upload("SPB_TESTING/"+ item.nombreArchivo,item.archivo);
      // if(item.progreso <=100) { item.upLoading = true;  continue;}
        
        let unsubscriber=  task.percentageChanges().subscribe( (porcentaje) => {
        item.progreso = Math.round(porcentaje);
        if (item.progreso == 100) 
        {
           //item.upLoading = false; 
           
           console.log(":::::porcentaje::::",porcentaje);
           console.log(":::::url::::",task.task.snapshot.downloadURL);
           
           //unsubscriber.unsubscribe();

           item.upLoading = false; 

           //this.SaveUrlDocument({nombre: item.nombreArchivo,url: item.url})
        }
        
        let subsUrl=  storageRef.getDownloadURL().subscribe( (URL) => {
            console.log(URL);
            item.url = URL;

            //if(URL!==undefined)
            if (item.progreso == 100) 
            {
              let _documento:IDocumento = {nombre:item.nombreArchivo,url: item.url,size:item.archivo.size} as unknown as IDocumento;
              console.log("documento:::documento", _documento);
              this.SaveUrlDocument( Object.assign({},_documento))
            }
          });
       
     });

     }
     
  }




  SalvarDocumentosFirebase02( documentos: FileItem[] ) {

    //const storageRef = firebase.storage().ref();  
    const storageRef = this.storage.ref(this.FILES_FOLDER); 
    
    

    for ( const item of documentos ) {

      item.upLoading = true;
      if ( item.progreso >= 100 ) {
        continue;
      }

      let task = this.storage.upload("SPB_TESTING/"+ item.nombreArchivo,item.archivo);  

      //const uploadTask  = storageRef.child(`${ this.FILES_FOLDER }/${ item.nombreArchivo }`)
      const uploadTask = storageRef.child("SPB_TESTING/"+ item.nombreArchivo)
                            .put( item.archivo );

      
      uploadTask.on( firebase.storage.TaskEvent.STATE_CHANGED,
              ( snapshot: firebase.storage.UploadTaskSnapshot ) =>
                          item.progreso = ( snapshot.bytesTransferred / snapshot.totalBytes ) * 100,
              ( error ) => console.error('Error al subir', error ),
              () => {
                console.log('Imagen cargada correctamente');
                item.url = uploadTask.snapshot.downloadURL;

                item.upLoading = false;
                //this.SaveUrlDocument({nombre: item.nombreArchivo,url: item.url});
              });
    }
  }

  /*
  EnviarDocumentosFirebase2( documentos: FileItem[] ) {

    //const storageRef = firebase.storage().ref();  
    const storageRef = this.storage.ref(this.FILES_FOLDER); 

    for ( const item of documentos ) {

      item.upLoading = true;
      if ( item.progreso >= 100 ) {
        continue;
      }

      let task:any  = storageRef;
      const uploadTask = storageRef.child(`${ this.FILES_FOLDER }/${ item.nombreArchivo }`)
                              .put( item.archivo );
      
      
      uploadTask.on( firebase.storage.TaskEvent.STATE_CHANGED,
              ( snapshot: firebase.storage.UploadTaskSnapshot ) =>
                          item.progreso = ( snapshot.bytesTransferred / snapshot.totalBytes ) * 100,
              ( error ) => console.error('Error al subir', error ),
              () => {

                console.log('Imagen cargada correctamente');
                item.url = uploadTask.snapshot.downloadURL;
                item.upLoading = false;
                this.SaveDocument({
                  nombre: item.nombreArchivo,
                  url: item.url
                });
              });
    }
  }
*/

  //SaveUrlDocument(documento:{nombre:string, url:string, size:number}){
   async SaveUrlDocument(documento:IDocumento){
        ///this.fbDisk.collection(`/${this.FILES_FOLDER}`).add(documento);
        
        let path0 = this.AsambleaId+"/Preregistro/Lista/";

        let tempDoc = {documento:this.documento.trim()};

        await this.fbDisk.collection(path0).doc(this.documento.trim()).update(Object.assign({},tempDoc))
        .then((resp)=>{
              console.log("Creado el enlace", resp);
        })
        .catch((err)=>{console.log("Error creando registro de enlace url", err);});


        let path = this.AsambleaId+"/Preregistro/Lista/"+this.documento.trim()+"/Documentos/";
        console.log("path:::::path:::::",path);

        //await this.fbDisk.collection(path).doc("1").set(Object.assign({},documento))
        await this.fbDisk.collection(path).add(Object.assign({},documento))
        .then((resp)=>{
              console.log("Creado el enlace", resp);
        })
        .catch((err)=>{console.log("Error creando registro de enlace url", err);});
  }

  DownloadDocument(){}
  DeleteDocument(){}
  UpdateDocument(){}

}
