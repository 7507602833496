import { Component, OnInit, Input, Output } from '@angular/core';
import {FbStorageService} from  './../../../../services/FirebaseStorage/fb-storage.service';
import { FileItem } from '../../../../services/common/filre-item';


@Component({
  selector: 'app-file-loader',
  templateUrl: './file-loader.component.html',
  styleUrls: ['./file-loader.component.css']
})
export class FileLoaderComponent implements OnInit {

  isOverElement:boolean=false;

  documentos: FileItem[]=[];

  @Input() documento: string;

  constructor(private _cargaDocService:FbStorageService){   }

  ngOnInit(): void {  }

  mouseOnElement(event){
    console.log(event);
  }

  CargarDocumentos(){ 

    if(this.documentos.length>0){
      this._cargaDocService.SalvarDocumentosFirebase(this.documentos,this.documento);
    }  
  }

 /*
  OnLoadFileChange(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);

    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {

      const binarystr: string = e.target.result;


    };
 }*/
 OnLoadFileChange(event: any) {
  /* wire up file reader */
  //const target: DataTransfer = <DataTransfer>(event.target);
  const target  = this._getTransferencia(event);

  if (target.files.length !== 1) {
    throw new Error('Cannot use multiple files');
  }
  const reader: FileReader = new FileReader();
  reader.readAsBinaryString(target.files[0]);
  reader.onload = (e: any) => {

    const binarystr: string = e.target.result;


  };
}
 
documentosLista:FileList;

private _extraerArchivos(documentosLista :FileList ){
        
  console.log(documentosLista);

  for(const propiedad in Object.getOwnPropertyNames(documentosLista)){
    
      const docTemp = documentosLista[propiedad];

      let fileItem = new FileItem(docTemp);

      /*if(this._canToAddDoc(fileItem)===true)
      {
        this.documentos.push(fileItem); 
      }*/

  }

  console.log(this.documentos);  
}

 private _getTransferencia(event : any)
 {
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
 }

  LimpiarDocumentos()
  {
    this.documentos = [];
  }

  HasDocuments():boolean
  {
      return this.documentos.length>0 ? true : false;
  }

}
