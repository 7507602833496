    import { Injectable } from "@angular/core";
    import { getLocaleDateFormat } from '@angular/common';
    import { DataService } from './../services/data/data.service';

    @Injectable()
    export class DashboardService{
        constructor(){ console.log("Service reponding;");}

        private QuorumData: IQuorumData = {
            Nombre:"Harold Villa A",
            QuorumActual: 79.67,
            TotalPresentes : 381,
            TotalRepresentados  : 451,
            TotalAusentes : 810
        }
        
        private QuorumDataChart: IQuorumDataChart = {
            Asamblea:"Quorum de Asamblea No presencial",
            Fecha: (new Date()),
            QuorumActual: 79.68,
            TotalPresentes : 381,
            TotalRepresentados  : 451,
            TotalAusentes : 81
        }

        public GetQuorumData()
        {
            

                    return this.QuorumData;
        }

        public GetQuorumDataChart()
        {
        return this.QuorumDataChart;
        }
    }

    export interface IQuorumData
    {
        Nombre: string;
            QuorumActual: Number;
            TotalPresentes : Number;
            TotalRepresentados  : Number;
            TotalAusentes : Number;
    }

    export interface IQuorumDataChart
    {
            Asamblea: string;  // nombre de la asamblea
            Fecha: Date;  // actual Fecha y hora de la asamblea
            QuorumActual: Number;  // Porcentaje accionario
            TotalPresentes : Number; // Número de asistentes 
            TotalRepresentados  : Number; // Número total de representados
            TotalAusentes : Number; // Número de personas no asistentes 
    }

