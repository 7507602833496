export class FileItem{

        public archivo:File;
        public nombreArchivo:string;
        public url: string
        public upLoading:boolean;
        public progreso:number;


        constructor(archivo:File){

                this.archivo = archivo;
                this.nombreArchivo= this.archivo.name;

                this.upLoading=false;
                this.progreso=0;
        }

}