import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from './../../Models/customer/customer';

@Injectable()
export class CustomerService {


    //result : Customer[];
    Customer: { id: number; name: string; country: { name: string; code: string; }; company: string; date: string; status: string; activity: number; representative: { name: string; image: string; }; }[];

    constructor(private http: HttpClient) {

          this.Customer=[
            {
              "id": 1000,
              "name": "James Butt",
              "country": {
                "name": "Algeria",
                "code": "dz"
              },
              "company": "Benton, John B Jr",
              "date": "2015-09-13",
              "status": "unqualified",
              "activity": 17,
              "representative": {
                "name": "Ioni Bowcher",
                "image": "ionibowcher.png"
              }
            }];
        }
      

    getCustomersSmall() {

            console.log("Cargando la data::::");
           // return this.Customer;
        return this.http.get<any>('assets/showcase/data/customers-small.json')
            .toPromise()
            .then(res => <Customer[]>res.data)
            .then(data => { return data; });
    }

    getCustomersMedium() {
        return this.http.get<any>('assets/showcase/data/customers-medium.json')
            .toPromise()
            .then(res => <Customer[]>res.data)
            .then(data => { return data; });
    }

    getCustomersLarge() {

        console.log("Cargando la data:::: large");
        //return this.Customer;
        
        return this.http.get<any>('assets/showcase/data/customers-large.json')
            .toPromise()
            .then(res => <Customer[]>res.data)
            .then(data => { return data; });
    }

    getCustomersXLarge() {
        return this.http.get<any>('assets/showcase/data/customers-xlarge.json')
            .toPromise()
            .then(res => <Customer[]>res.data)
            .then(data => { return data; });
    }

}