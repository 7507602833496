import { Component, ViewChild } from '@angular/core';
import { DataService } from '../../services/data/data.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {IPersonaRegistro, IPreregistro} from './../../Models/Poderes/ipersona-registro';
import {PersonaRegistro} from './../../Models/Poderes/persona-registro';
import { Cypher } from './../../Logic/Cypher';
import Swal from 'sweetalert2';
import {environment} from  './../../../environments/environment';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { FileLoaderComponent } from '../../dashboard/custom/fileLoader/file-loader/file-loader.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent {

  formaPreregistro: FormGroup;

  @ViewChild(FileLoaderComponent) loaderFile: FileLoaderComponent;

  //personaRegistro:PersonaRegistro;
   personaRegistro:IPersonaRegistro;
   
   _htmlContent:string;
   preregistro: IPreregistro;


  AsambleaId:string;
  krypto:Cypher;

  constructor( private fb: FormBuilder, private _dataService : DataService, private http:HttpClient) {
    
    this.crearFormulario(); 

    this.krypto =  new Cypher();
  
    this.AsambleaId = "Simulacro";

    this._htmlContent = '<p>Probando envio desde api:  {Token} de entrada</p>';

  }

  crearFormulario(){

    this.formaPreregistro =  this.fb.group(
      {
         nombre:  ['',  Validators.required]
        ,email: ['', Validators.required]
        ,tipoDocumento:  ['CC',  Validators.required]
        ,documento:  ['', Validators.required]
        ,clave: ['', Validators.required]
        ,claveRepeat: ['', Validators.required]
      });
 }

   get nombreInvalido()
   {
      return this.formaPreregistro.get('nombre').invalid && this.formaPreregistro.get('nombre').touched;
   }

   get documentoInvalido()
   {
      return this.formaPreregistro.get('documento').invalid && this.formaPreregistro.get('documento').touched;
   }

   get tipoDocInvalido()
   {
      return this.formaPreregistro.get('tipoDocumento').invalid && this.formaPreregistro.get('tipoDocumento').touched;
   }

   get emailInvalido()
   {
      return this.formaPreregistro.get('email').invalid && this.formaPreregistro.get('email').touched;
   }

   get claveInvalida()
   {
      return this.formaPreregistro.get('clave').invalid && this.formaPreregistro.get('clave').touched;   
   }
   
   get claveRepeatInvalida()
   {
      let result = this.formaPreregistro.get('clave').invalid && this.formaPreregistro.get('clave').touched;

      let IsVerified = this.formaPreregistro.get('clave').value === this.formaPreregistro.get('claveRepeat').value ? true: false;

      let rslt = IsVerified && !result ? true:false;

      return rslt ? false:true;
   }


   get sumaryAlert()
   {

      let result = false;

      result = this.nombreInvalido || this.claveInvalida || this.claveRepeatInvalida || this.documentoInvalido || this.emailInvalido;

     return result
   }


  async SendmailApi(_email:string )
   { 

      await this.GetDataAsamblea();

       this.InjectTemplateData(); 

        var options = {
          method: 'POST',
          url:   environment.InfosolConfig.sendMailMethod, //'https://api.sendinblue.com/v3/smtp/email',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'api-key':   environment.InfosolConfig.sendAPIKEY.toString()  // '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'
          },
          body: {
            //sender: {name: 'Asamblea.SPB', email: 'haroldvilla@gmail.com'},
            sender: {name: 'Asamblea.SPB', email: 'info@infosolcolombia.com'},
            to: [{name: this.personaRegistro.nombre , email: _email.toString()}],
            tags: ['spb'],
            htmlContent: this._htmlContent,//'<p>Probando envio desde api</p>',
            textContent: 'es necesario?',
            subject: 'Pre-registro Asamblea SPB',

          },
          json: true
        };
        

        //this.http.request("POST", "https://api.sendinblue.com/v3/smtp/email",options)
        this.http.request("POST", environment.InfosolConfig.sendMailMethod,options)
          .subscribe((resp)=>{
                console.log(resp);
          });
   }


   InjectTemplateData()
   {
        this._htmlContent = this._htmlContent.replace("{Token}",this.personaRegistro.documento);
        this._htmlContent = this._htmlContent.replace("{Name}",this.personaRegistro.nombre);
   }

   
 async GetDataAsamblea()
  {
      let path = this.AsambleaId;

      let refDoc = this._dataService.GetData(path).doc("Preregistro");

     await refDoc.get()
      .then((resp)=>{

            this.preregistro =resp.data() as IPreregistro;
            this._htmlContent = this.preregistro.mailTemplate.trim();
            console.log(this.preregistro);

      })
      .catch((error)=>{
            console.log("error:::error::::",error);
      })


  }


  CrearRegistro()
  {

    if( this.formaPreregistro.invalid )
    {

      return Object.values( this.formaPreregistro.controls ).forEach(control=>
        {
            control.markAllAsTouched();
        })
    }

if(this.sumaryAlert===false){

    //  console.log(this.formaPreregistro.value);  
    //  preregistro

    this.personaRegistro = this.formaPreregistro.value as IPersonaRegistro;
    
    //this.personaRegistro.clave = this.krypto.Encrypt2(this.personaRegistro.clave);

    console.log("personaRegistro:::::: --- -",this.personaRegistro);

    let path  = this.AsambleaId+"/Preregistro/Lista";

    let refDoc = this._dataService.Create(path);

    refDoc.doc(this.personaRegistro.documento).set( Object.assign({},this.personaRegistro))
    .then((resp)=>
    { 
          console.log(resp);

          this.SendmailApi(this.personaRegistro.email);

          Swal.fire('Pre-registro','pre-registro completado - Revise su correo electrónico, y  verifique la cuenta','success');
    })
    .catch((error)=>{
            console.log(error);
    })
}


    
}


}
