import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import { environment } from './../../environments/environment';
import { stringify } from 'querystring';

export class Cypher {

   private ciphertext: any;
   private CryptoJS : any;

   constructor(){
    
      this.CryptoJS = require("crypto-js");

    }

   EncodeBase64(msg:string){
        try {
            /*var wordArray = this.CryptoJS.enc.Utf8.parse(msg);
            var  base64 = this.CryptoJS.enc.Base64.stringify(wordArray);
            return base64;*/
            return msg; /// temporal
          } catch (e) {
            console.log(e);
          }
   }


   EncodeBase642(msg:string){
    try {
        var wordArray = this.CryptoJS.enc.Utf8.parse(msg);
        var  base64 = this.CryptoJS.enc.Base64.stringify(wordArray);
        return base64;
        
      } catch (e) {
        console.log(e);
      }
}

DecodeBase64(base64:string){
        try {
           
          /*var parsedWordArray = this.CryptoJS.enc.Base64.parse(base64);
          var parsedStr = parsedWordArray.toString(this.CryptoJS.enc.Utf8);
          return parsedStr;    
          */

         return base64; // temporal

            //return this.CryptoJS.enc.Base64.stringify(encoded);
          } catch (e) {
            console.log(e);
          }
   }


DecodeBase642(base64:string){
  
    try {
      var parsedWordArray = this.CryptoJS.enc.Base64.parse(base64);
      var parsedStr = parsedWordArray.toString(this.CryptoJS.enc.Utf8);
      return parsedStr;    
        //return this.CryptoJS.enc.Base64.stringify(encoded);
      } catch (e) {
        console.log(e);
      }
}

Encrypt(msg:string)
{
    try {
        
          //return this.CryptoJS.AES.encrypt(JSON.stringify(msg), environment.InfosolConfig.KriptoKey.toString()).toString();    
          return msg; 

      } catch (e) {
        console.log(e);
      }
   }

Encrypt2(msg:string)
   {
    try {
          return this.CryptoJS.AES.encrypt(JSON.stringify(msg), environment.InfosolConfig.KriptoKey.toString()).toString();    
      } catch (e) {
        console.log(e);
      }
}


   Decript(cryptoMsg:string)
   {
       //let result:string =this.CryptoJS.AES.decript(cryptoMsg,environment.InfosolConfig.KriptoKey).toString(this.CryptoJS.enc.Utf8);
       //return result;
    try {
      /*
        const bytes = this.CryptoJS.AES.decrypt(cryptoMsg, environment.InfosolConfig.KriptoKey.toString());
        if (bytes.toString()) {
          return JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8));
        }
        return cryptoMsg;
          */

         return  cryptoMsg;


      } catch (e) {
        console.log(e);
      }

   }

   Decript2(cryptoMsg:string)
   {
    try {
        const bytes = this.CryptoJS.AES.decrypt(cryptoMsg, environment.InfosolConfig.KriptoKey.toString());
        
        if (bytes.toString()) 
        {
          return JSON.parse(bytes.toString(this.CryptoJS.enc.Utf8));
        }
        return cryptoMsg;
      } catch (e) 
      {
        console.log(e);
      }
   }


   FactorKrypt(data:string){

       return parseInt(data) * 63;
       //return data;
   }

   
   FactorDekrypt(data:string){

    return parseInt(data) / 63;
    //return data;

   }




}
