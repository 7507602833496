import { Injectable } from '@angular/core';

import * as FileSaver from 'file-saver';  

import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';  
const EXCEL_EXTENSION = '.xlsx';  

@Injectable({
  providedIn: 'root'
})
export class CommonToolService {

  ExcelData:any;

  constructor() { }

 public convertToBoolean(input: string): boolean | undefined {
    try {
        return JSON.parse(input);
    }
    catch (e) {
        return undefined;
    }
}

 public OnFileChange(event: any) {
  /* wire up file reader */
  const target: DataTransfer = <DataTransfer>(event.target);
  if (target.files.length !== 1) {
    throw new Error('No se adminten multiples archivos');
  }

  const reader: FileReader = new FileReader();
  reader.readAsBinaryString(target.files[0]);

  reader.onload = (e: any) => {
    /* create workbook */
    const binarystr: string = e.target.result;
    const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

    /* selected the first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    /* save data */
    //const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
    this.ExcelData = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
    //console.log("XLSX:::::::",this.ExcelData); // Data will be logged in array format containing objects
  };

  
}



public OnFileUpload(event: any) {
  /* wire up file reader */
  const target: DataTransfer = <DataTransfer>(event.target);
  if (target.files.length !== 1) {
    throw new Error('No se adminten multiples archivos');
  }

  return target;

  /*const reader: FileReader = new FileReader();
  reader.readAsBinaryString(target.files[0]);
  return reader;*/
}

  public exportAsExcelFile(json: any[], excelFileName: string): void 
  {  
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);  
    const workbook : XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });  
    this.saveAsExcelFile(excelBuffer, excelFileName);  
  }  

  private saveAsExcelFile(buffer: any, fileName: string): void {  
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});  
     FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);  
  }  

 

  public exportAsExcelFile2(json: any[], excelFileName: string, dsName:string): void 
  {  
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);  
    const workbook : XLSX.WorkBook = { Sheets: { dsName: worksheet }, SheetNames: [dsName] };  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });  
    this.saveAsExcelFile2(excelBuffer, excelFileName);  
  }  

  
  private saveAsExcelFile2(buffer: any, fileName: string): void {  
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});  
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);  
 }  


}
