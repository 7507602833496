export const SendinblueAPI = {
     AUTH0: false
    ,APK:"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"
    ,Contacts :{
         createContact : "https://api.sendinblue.com/v3/contacts"
        ,createContactDOI : "https://api.sendinblue.com/v3/contacts/doubleOptinConfirmation"
        ,updateContact:  "https://api.sendinblue.com/v3/contacts/identifier"
        ,deleteContact: "https://api.sendinblue.com/v3/contacts/identifier"
        ,createList:"https://api.sendinblue.com/v3/contacts/lists"
        ,getContactsInList: "https://api.sendinblue.com/v3/contacts/lists/listId/contacts"
        ,importContacts:"https://api.sendinblue.com/v3/contacts/import"
        ,exportContacts:"https://api.sendinblue.com/v3/contacts/export"
    },        
    TransactMail:{
            sendMail:"https://api.sendinblue.com/v3/smtp/email"
           ,getAll:"https://api.sendinblue.com/v3/smtp/statistics/events"
    },        
    TransactSMS:{
        sendSMS:"https://api.sendinblue.com/v3/transactionalSMS/sms"
       ,getAll:"https://api.sendinblue.com/v3/transactionalSMS/statistics/events"
    }        
}