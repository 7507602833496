import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
//import { StorageService } from './services/localStorage/StorageService';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit, OnDestroy{
  constructor(private router: Router) { 
    console.log("Iniciando todo... **********");
  }
  ngOnDestroy(): void {
    //throw new Error("Method not implemented.");
      //localStorage.setItem("Component","finalizando aplicacion..");
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
